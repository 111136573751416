import React, {useState} from "react";
import axios from "axios";
import {Formik, Form} from "formik";
import SubmitButton from "../../components/Forms/SubmitButton";
import SettingsSelect from "../../components/Forms/SettingsSelect";
import DatePicker from "react-datepicker";
import moment from "moment";

export default function ReportChatAudit() {
	const [loading, setLoading] = useState(false);
	const [auditLogs, setAuditLogs] = useState([]);
	const [fromDate, setFromDate] = useState(null);
	const [toDate, setToDate] = useState(null);

	return (
		<>
			<div className="w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5">
				<h1>User Activity</h1>

				<Formik
					initialValues={{
						audit_type: "chats_sent",
					}}
					onSubmit={async (values, {setSubmitting, setErrors, resetForm}) => {
						const formattedFromDate = fromDate ? moment(fromDate).format("YYYY-MM-DD") : null;
						const formattedToDate = toDate ? moment(toDate).format("YYYY-MM-DD") : null;
						setLoading(true);

						await axios({
							method: "post",
							url: `${process.env.REACT_APP_API_URL}reports/get_user_activity`,
							data: {audit_type: values.audit_type, fromDate: formattedFromDate, toDate: formattedToDate},
						})
							.then((response) => {
								setAuditLogs(response.data);
							})
							.catch((error) => {
								console.log(error);
							})
							.finally(() => {
								setLoading(false);
								setSubmitting(false);
							});
					}}
				>
					{({values, errors, setFieldValue, handleSubmit, isValid, isSubmitting, dirty}) => (
						<Form onSubmit={handleSubmit}>
							<div>
								<div className="grid md:lg:grid-cols-3 gap-12 mt-5 mb-5 ">
									<div>
										<SettingsSelect
											label="What sort of report would you like to run?"
											name="audit_type"
											onChange={setFieldValue}
											value={values.audit_type}
											error={errors.audit_type}
											options={[
												{value: "chats_sent_month", label: "Chats Sent by Month"},
												{value: "chats_sent_day", label: "Chats Sent by Day"},
												{value: "bookings_updated", label: "Bookings Updated"},
												{value: "new_chats_day", label: "New Chats by Day"},
											]}
										/>
									</div>

									<div>
										<label className="block text-sm font-medium text-gray-700">From Date</label>
										<DatePicker
											selected={fromDate}
											onChange={(value) => {
												setFromDate(value);
											}}
											className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
											dateFormat={"d MMMM yyyy"}
											peekNextMonth
											showMonthDropdown
											showYearDropdown
											dropdownMode="select"
										/>
									</div>

									<div>
										<label className="block text-sm font-medium text-gray-700">To Date</label>
										<DatePicker
											selected={toDate}
											onChange={(value) => {
												setToDate(value);
											}}
											className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
											dateFormat={"d MMMM yyyy"}
											peekNextMonth
											showMonthDropdown
											showYearDropdown
											dropdownMode="select"
										/>
									</div>
								</div>
								<SubmitButton name="Submit" disabled={!isValid || isSubmitting || !dirty}
											  isSubmitting={isSubmitting} text="View Data"/>
							</div>

							<div className="mt-5">
								{loading === true ? (
									<p>Loading...</p>
								) : (
									<table className="w-full">
										<thead>
										<tr>
											<th width="200" className="text-left">
												Date
											</th>

											{values.audit_type === "new_chats_day" ? (
												<th width="200" className="text-left">
													New Chats
												</th>
											) : (
												<th width="200" className="text-left">
													Romi User
												</th>
											)}

											{values.audit_type !== "bookings_updated" && values.audit_type !== "new_chats_day" && (
												<th width="200" className="text-left">
													Messages Sent
												</th>
											)}

											{values.audit_type === "bookings_updated" && values.audit_type !== "new_chats_day" && (
												<th width="200" className="text-left">
													Bookings Updated
												</th>
											)}

											{values.audit_type === "bookings_updated" && values.audit_type !== "new_chats_day" && (
												<th width="200" className="text-left">
													Total Changes
												</th>
											)}
										</tr>
										</thead>

										<tbody>
										{auditLogs?.map((log, index) => (
											<tr key={index}>
												<td>{log.dt}</td>

												{values.audit_type != "new_chats_day" && (
													<td>{log.name} {log.surname}</td>
												)}

												{values.audit_type === "new_chats_day" && (
													<td>{log.total_bookings}</td>
												)}

												{values.audit_type !== "bookings_updated" && (
													<td>{log.messages_sent}</td>
												)}

												{values.audit_type === "bookings_updated" && values.audit_type !== "new_chats_day" && (
													<td>{log.unique_bookings}</td>
												)}

												{values.audit_type === "bookings_updated" && values.audit_type !== "new_chats_day" && (
													<td>{log.total_changes}</td>
												)}
											</tr>
										))}
										</tbody>
									</table>
								)}
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</>
	);
}
