import React from "react";
import {Link} from "react-router-dom";

export default function ResroucesPage() {
	return (
		<div className="px-1 sm:px-4 my-2 xl:my-0 xl:p-4 h-screen w-full">
			<div className="flex justify-between mb-8 items-center">
				<h1 className="text-lg font-bold">Resources</h1>
			</div>

			<div>
				<a href={process.env.REACT_APP_LARAVEL_URL + "resources/Intake Workshop - Copy.xlsx"} target="_blank"
				   className="bg-primary text-white px-4 py-2 rounded-full">
					2025 Pricing
				</a>
			</div>
		</div>
	);
}
