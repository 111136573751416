import React, {useEffect, Fragment, useState} from "react";
import {closeModal} from "../../store/modal/modalReducer";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {Dialog, Transition, Listbox} from "@headlessui/react";
import ErrorBoundary from "../../containers/ErrorBoundary";
import {Formik, Form, Field} from "formik";
import SubmitButton from "../Forms/SubmitButton";
import SettingsTextInput from "../Forms/SettingsTextInput";
import SettingsSelect from "../Forms/SettingsSelect";
import CustomButton from "../Helpers/CustomButton";
import Select from "react-select";
import {Link} from "react-router-dom";
import {getBooking} from "../../store/booking/bookingActions";
import $ from "jquery";
import moment from "moment";
import {toast} from "react-toastify";
import NotificationToast from "../Helpers/NotificationToast";
import {faPlus} from "@fortawesome/pro-regular-svg-icons";

export default function ModalPropUnit(props) {
	const dispatch = useDispatch();
	const {current_staff} = useSelector((state) => state.auth);
	const {settings} = useSelector((state) => state.settings);
	const [open] = useState(true);
	const [loaded, setLoaded] = useState(false);
	const [unlinking, setUnlinking] = useState(false);
	const [units, setUnits] = useState([]);
	const [unitTypes, setUnitTypes] = useState([]);
	const [chosenUnit, setChosenUnit] = useState(
		$(props.thisbed).attr("unitid") > 0
			? parseInt($(props.thisbed).attr("unitid"))
			: 0
	);
	const [bedList, setBedList] = useState([]);
	const [thisType, setThisType] = useState(
		$(props.thisbed).attr("bedid") > 0 ? 1 : 0
	);
	const [bookings, setBookings] = useState([]);
	const [waitlist, setWaitlist] = useState([]);
	const [bedDelinked, setBedDelinked] = useState(false);
	// console.log(props.thisbed);
	const [wiFiLoading, setWiFiLoading] = useState(false);
	const [updateInfo, setUpdateInfo] = useState(true);

	const [initialValues, setInitialValues] = useState({
		form_type:
			process.env.REACT_APP_COLOUR == "UC"
				? 0
				: props.thisbed.hasAttribute("bedid")
					? 1
					: 2,
		unit_type_id:
			$(props.thisbed).attr("unit_type_id") > 0
				? parseInt($(props.thisbed).attr("unit_type_id"))
				: 0,
		price: 0,
		hot_meter: "",
		cold_meter: "",
		area: "",
		rental: "",
		comment: "",
		electricity_meter: "",
		bed_deposit: 0,
		name: "",
		unit_id:
			$(props.thisbed).attr("unitid") > 0
				? parseInt($(props.thisbed).attr("unitid"))
				: 0,
		bed_price_nsfas: 0,
		bed_status: $(props.thisbed).hasClass("blocked") ? 1 : 0,
		bed_deposit_nsfas: 0,
		id:
			$(props.thisbed).attr("bedid") > 0
				? parseInt($(props.thisbed).attr("bedid"))
				: 0,
		property_id: props.property_id,
		floor_id: props.floor_id,
		gender: 0,
		allowed_type: $(props.thisbed).hasClass("allowed_type_0")
			? 0
			: $(props.thisbed).hasClass("allowed_type_1")
				? 1
				: 2,
		wifi: [],
	});

	useEffect(async () => {
		if (props.property_id > 0 && props.floor_id > 0 && units.length === 0) {
			axios({
				method: "get",
				url: `${process.env.REACT_APP_API_URL}get_bed_info_new/${props.property_id}/${props.floor_id}/${thisType}`,
			})
				.then((response) => {
					//   setBed( response.data.bed );

					setUnits(response.data.units);
					setUnitTypes(response.data.unit_types);
				})
				.catch((error) => {
					alert("units load failed");
				});
		}

		if (chosenUnit > 0 && updateInfo === true) {
			setUpdateInfo(false);
			axios({
				method: "get",
				url: `${process.env.REACT_APP_API_URL}get_unit_bookings/${chosenUnit}`,
			})
				.then((response) => {
					let allres = response.data;
					// console.log(response.data);
					allres.forEach(function (x) {
						x.bed = {id: initialValues.id};
					});

					setBookings(response.data);
				})
				.catch((error) => {
					console.log(error);
				});

			axios({
				method: "get",
				url: `${process.env.REACT_APP_API_URL}get_unit_bookings_waitlist/${chosenUnit}`,
			})
				.then((response) => {
					let allres = response.data;

					allres.forEach(function (x) {
						x.bed = {id: initialValues.id};
					});

					// console.log(response.data)
					setWaitlist(response.data);
				})
				.catch((error) => {
					console.log(error);
				});

			axios({
				method: "get",
				url: `${process.env.REACT_APP_API_URL}get_unit_info/${chosenUnit}`,
			})
				.then((response) => {
					setInitialValues(response.data);
					setLoaded(true);
				})
				.catch((error) => {
					console.log(error);
					alert("unit load failed");
				});
		}
		// }
	}, [chosenUnit, units, bedDelinked, updateInfo]);

	const options_type = [{value: 0, label: "Standalone unit"}];
	var month_strt = moment().add(1, "M").format("MM");
	var year_strt = moment().format("Y");
	var lease_start_string = year_strt + "-" + month_strt + "-01";
	var month_end = moment().format("MM");
	var year_end = moment().add(1, "Y").format("Y");
	var lease_end_string = moment(new Date(year_end, month_end, 0)).format(
		"Y-MM-D"
	);

	if (process.env.REACT_APP_COLOUR === "UC") {
		lease_start_string = "2025-02-01";
		lease_end_string = "2025-11-30";
	}

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			boxShadow: "none",
			border:
				state.isFocused && "none" ? "1px solid #9F7D38" : "1px solid #d1d5db",
			cursor: "pointer",
			"&:hover": {
				border: state.isFocused ? "1px solid #9F7D38" : "1px solid #d1d5db",
				cursor: "pointer",
			},
			borderRadius: "10px",
			padding: 0,
		}),
		container: (provided, state) => ({
			...provided,
			padding: 0,
			border: 0,
		}),
		dropdownIndicator: (provided, state) => ({
			...provided,
			color: "#e7ebe5",
		}),
	};

	function unlinkBed() {
		if (window.confirm("Are you sure?")) {
			setUnlinking(true);

			if (
				$(props.thisbed).attr("bedid") > 0 ||
				$(props.thisbed).hasClass("mapped")
			) {
				$(props.thisbed).attr("class", "");
				$(props.thisbed).attr("fill", "rgb(228,228,228)");
				$(props.thisbed).removeAttr("bedid");
				$(props.thisbed).removeAttr("unitid");
				$(props.thisbed).removeAttr("id");

				//Save SVG
				let map_svg = $(".map_svg").html();

				axios({
					method: "post",
					url: `${process.env.REACT_APP_API_URL}save_svg_map/${props.floor_id}`,
					data: {
						map: map_svg,
					},
				})
					.then((response) => {
						window.location.reload();
					})
					.catch((error) => {
						alert("failed to save map");
						console.log(error);
					});
			} else if ($(props.thisbed).hasClass("room")) {
				$(props.thisbed).attr("class", "");
				$(props.thisbed).removeAttr("unitid");
				$(props.thisbed).removeAttr("id");

				//Save SVG
				let map_svg = $(".map_svg").html();

				axios({
					method: "post",
					url: `${process.env.REACT_APP_API_URL}save_svg_map/${props.floor_id}`,
					data: {
						map: map_svg,
					},
				})
					.then((response) => {
						window.location.reload();
					})
					.catch((error) => {
						alert("failed to save map");
						console.log(error);
					});
			}
		}
	}

	function delinkBed(bed_id) {
		setBedDelinked(true);
		props.setMappedBeds(true);
		axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}delink_user_bed`,
			data: {
				booking_id: bed_id,
				user: current_staff,
				move_location: 1,
			},
		})
			.then((response) => {
				// dispatch(getBooking(props.current_booking.id));
				// setChosenBed(0);
				// dispatch(closeModal());
				setBedDelinked(false);
				props.setMappedBeds(false);
				// console.log(response)
			})
			.catch((error) => {
				alert("properties failed");
			});
	}

	async function createWifiAccount(unit_id) {
		setWiFiLoading(true);

		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}wifi-create-account-unit`,
			data: {unit_id: unit_id},
		})
			.then((response) => {
				toast(
					<NotificationToast
						title={"WIFI Account Created"}
						message={"WiFi Created"}
					/>
				);
			})
			.catch((response) => {
				toast(
					<NotificationToast
						title={"WIFI Issue"}
						message={response.data}
						type="error"
					/>
				);
			})
			.finally(() => {
				setWiFiLoading(false);
				setUpdateInfo(true);
			});
	}

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				static
				className="fixed z-50 inset-0 overflow-y-auto"
				open={open}
				onClose={() => dispatch(closeModal())}
			>
				<div
					className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true"
					>
            &#8203;
          </span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<ErrorBoundary>
							<div
								className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6">
								<div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
									<CustomButton
										type="secondary"
										className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
										onClick={() => dispatch(closeModal())}
									>
										Close
									</CustomButton>
								</div>
								<div className="pr-8 w-full">
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
										<Dialog.Title
											as="h1"
											className="text-lg font-medium text-gray-900 flex justify-between"
										>
                      <span>
                        {current_staff?.status === "Admin" ? "Edit" : "View"}{" "}
						  Unit
                      </span>

											{current_staff?.status === "Admin" &&
												(initialValues.id > 0 || initialValues.unit_id > 0) && (
													<CustomButton
														type="alert"
														text={
															unlinking === true
																? "Please wait..."
																: "Unlink on Map"
														}
														onClick={() => unlinkBed()}
													/>
												)}
										</Dialog.Title>
										<Formik
											initialValues={initialValues}
											validate={(values) => {
												const errors = {};

												return errors;
											}}
											enableReinitialize="true"
											onSubmit={async (
												values,
												{setSubmitting, setErrors, resetForm}
											) => {
												setSubmitting(true);

												let update_bulk = [];

												let save_ok = true;

												await axios({
													method: "post",
													url: `${process.env.REACT_APP_API_URL}save_new_unit`,
													data: values,
												})
													.then((response) => {
														if (values.form_type === 1 && values.id === -1) {
															values.id = response.data;
														}

														if (
															values.form_type === 0 &&
															values.unit_id === -1
														) {
															values.unit_id = response.data;
														}

														return true;
													})
													.catch((error) => {
														alert("bed/unit save failed");

														save_ok = false;
													});

												if (save_ok === true) {
													$(props.thisbed).attr("class", "");

													//Add info to SVG file
													if (values.form_type === 1) {
														// save bed info to SVG file
														if (values.bed_status === 1) {
															$(props.thisbed).addClass("blocked");
														} else {
															$(props.thisbed).removeClass("blocked");
														}

														$(props.thisbed).attr("bedid", values.id);
														$(props.thisbed).attr("id", "bed_" + values.id);

														//Remove style attribute
														$(props.thisbed).attr("style", "");

														let cls_add = "";
														let cls_step = "";

														if (values.unit_type_id > 0) {
															cls_add +=
																cls_step + "room_type_" + values.unit_type_id;
															cls_step = " ";
														}

														if (values.gender > 0) {
															cls_add += cls_step + "gender_" + values.gender;
															cls_step = " ";
														}

														if (values.allowed_type > 0) {
															cls_add +=
																cls_step +
																"allowed_type_" +
																values.allowed_type;
															cls_step = " ";
														}

														$(props.thisbed).addClass("mapped " + cls_add);
													} else if (
														values.form_type === 0 &&
														process.env.REACT_APP_COLOUR == "QL"
													) {
														// save standalone room info to svg
														$(props.thisbed).attr("unitid", values.id);
														$(props.thisbed).attr("id", "unit_", values.id);
														$(props.thisbed).attr(
															"unit_type_id",
															values.unit_type_id
														);

														// remove style attribute
														$(props.thisbed).attr("style", "");

														let cls_add = "";
														let cls_step = "";
														if (values.unit_type_id > 0) {
															cls_add +=
																cls_step + "room_type_" + values.unit_type_id;
															cls_step = " ";
														}

														$(props.thisbed).addClass("mapped");
														$(props.thisbed).attr(
															"id",
															"unit_" + values.unit_id
														);
													} else if (
														values.form_type === 0 &&
														process.env.REACT_APP_COLOUR == "UC"
													) {
														// save unit with beds

														$(props.thisbed).attr("room", "1");
														$(props.thisbed).addClass("room");
														$(props.thisbed).attr(
															"id",
															"unit_" + values.unit_id
														);

														//Bulk bed feature

														// $("[unitid='" + values.unit_id + "']").each(
														//   function () {
														//     let bed_id = parseInt($(this).attr("bedid"));

														//     if (bed_id > 0) {
														//       $(this).addClass(
														//         "allowed_type_" + values.allowed_types
														//       );
														//       update_bulk.push({
														//         bed_id: bed_id,
														//         allowed_type: values.allowed_type,
														//       });
														//     }
														//   }
														// );
													}

													$(props.thisbed).attr("unitid", values.unit_id);

													//Save SVG
													let map_svg = $(".map_svg").html();

													await axios({
														method: "post",
														url: `${process.env.REACT_APP_API_URL}save_svg_map/${props.floor_id}`,
														data: {
															map: map_svg,
															update_bulk: update_bulk,
														},
													})
														.then((response) => {
															window.location.reload();
														})
														.catch((error) => {
															alert("failed to save map");
															console.log(error);
														});
												}
											}}
										>
											{({
												  values,
												  touched,
												  errors,
												  setFieldValue,
												  setFieldTouched,
												  handleSubmit,
												  isSubmitting,
												  isValid,
												  dirty,
											  }) => (
												<Form
													onSubmit={handleSubmit}
													className="space-y-8 m-4 w-full "
												>
													{Object.keys(errors).map((key) => (
														<li
															key={key}
															className="text-red-400 col-span-1 text-sm font-light"
														>
															{errors[key]}
														</li>
													))}
													<div className="flex space-x-4">
														{/* {current_staff?.status === "Admin" && ( */}
														<div className="w-1/2">
															<div className="flex space-x-4 mb-6">
																<div className="w-1/2 space-y-6">
																	<div>
																		<label
																			htmlFor="unit_id"
																			className="text-sm font-medium text-gray-700"
																		>
																			What is this?
																		</label>
																		<Select
																			id="form_type"
																			name="form_type"
																			options={options_type}
																			isDisabled={
																				current_staff?.status === "Admin"
																					? false
																					: true
																			}
																			onChange={(val) => {
																				setFieldValue("form_type", val.value);
																				setThisType(val.value);
																			}}
																			onBlur={setFieldTouched}
																			value={
																				options_type && options_type.length > 0
																					? options_type.find(
																						(option) =>
																							option.value ===
																							values.form_type
																					)
																					: ""
																			}
																			components={{
																				IndicatorSeparator: () => null,
																			}}
																			styles={customStyles}
																			className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
																		/>
																		<p className="text-red-400 text-sm">
																			{errors.unit_id}
																		</p>
																	</div>
																</div>

																<div className="w-1/2 space-y-6">
																	<div>
																		<label
																			htmlFor="unit_id"
																			className="text-sm font-medium text-gray-700"
																		>
																			Unit
																		</label>
																		<Select
																			id="unit_id"
																			name="unit_id"
																			options={units}
																			isDisabled={
																				current_staff?.status === "Admin"
																					? false
																					: true
																			}
																			onChange={(val) => {
																				setFieldValue("unit_id", val.value);
																				setChosenUnit(val.value);
																			}}
																			onBlur={setFieldTouched}
																			value={
																				units && units.length > 0
																					? units.find(
																						(option) =>
																							option.value ===
																							parseInt(values.unit_id)
																					)
																					: ""
																			}
																			components={{
																				IndicatorSeparator: () => null,
																			}}
																			styles={customStyles}
																			className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
																		/>
																		<p className="text-red-400 text-sm">
																			{errors.unit_id}
																		</p>
																	</div>
																</div>
															</div>
															<div className="flex space-x-4 mb-6">
																<div className="w-1/2 space-y-6">
																	<Field
																		name="name"
																		type="text"
																		as={SettingsTextInput}
																		label={"Unit Name"}
																		disabled={
																			current_staff?.status === "Admin"
																				? false
																				: true
																		}
																	/>
																</div>

																<div className="w-1/2 space-y-6">
																	<SettingsSelect
																		id="unit_type_id"
																		name="unit_type_id"
																		options={unitTypes}
																		label={
																			values.form_type === 1
																				? "Bed Pricing"
																				: "Unit Type"
																		}
																		disabled={
																			current_staff?.status === "Admin"
																				? false
																				: true
																		}
																		onchange={setFieldValue}
																		onBlur={setFieldTouched}
																		value={values.unit_type_id}
																		touched={touched.unit_type_id}
																		error={errors.unit_type_id}
																	/>
																</div>
															</div>
															<div className="flex space-x-4 mb-6">
																<div className="w-1/2 space-y-6">
																	<Field
																		label="Electricity Meter Number"
																		name="electricity_meter"
																		type="text"
																		value={values.electricity_meter}
																		as={SettingsTextInput}
																		disabled={
																			current_staff?.status === "Admin"
																				? false
																				: true
																		}
																	/>
																</div>

																<div className="w-1/2 space-y-6">
																	<Field
																		label="Hot meter number"
																		name="hot_meter"
																		type="text"
																		value={values.hot_meter}
																		as={SettingsTextInput}
																		disabled={
																			current_staff?.status === "Admin"
																				? false
																				: true
																		}
																	/>
																</div>
															</div>
															<div className="flex space-x-4 mb-6">
																<div className="w-1/2 space-y-6">
																	<Field
																		label="Cold meter number"
																		name="cold_meter"
																		type="text"
																		value={values.cold_meter}
																		as={SettingsTextInput}
																		disabled={
																			current_staff?.status === "Admin"
																				? false
																				: true
																		}
																	/>
																</div>

																<div className="w-1/2 space-y-6">
																	<Field
																		label={
																			<p>
																				Area (m<sup>2</sup>)
																			</p>
																		}
																		name="area"
																		type="text"
																		value={values.area}
																		as={SettingsTextInput}
																		disabled={
																			current_staff?.status === "Admin"
																				? false
																				: true
																		}
																	/>
																</div>
															</div>

															<div className="flex space-x-4 mb-6">
																<div className="w-1/2 space-y-6">
																	{/* {process.env.REACT_APP_COLOR == "QP" && (
                                    
                                  )} */}
																	<Field
																		label={
																			<p>
																				Area External (m<sup>2</sup>)
																			</p>
																		}
																		name="area_external"
																		type="text"
																		value={values.area_external}
																		as={SettingsTextInput}
																		disabled={
																			current_staff?.status === "Admin"
																				? false
																				: true
																		}
																	/>
																</div>

																<div className="w-1/2 space-y-6">
																	{/* {process.env.REACT_APP_COLOR == "QP" && (
                                    
                                  )} */}
																	<Field
																		label={<p>Exclusive Use Area</p>}
																		name="exclusive_use"
																		type="text"
																		value={values.exclusive_use}
																		as={SettingsTextInput}
																		disabled={
																			current_staff?.status === "Admin"
																				? false
																				: true
																		}
																	/>
																</div>
															</div>

															<div className="flex space-x-4 mb-6">
																<div className="w-1/2 space-y-6">
																	<Field
																		label="Unit Price"
																		name="price"
																		type="text"
																		as={SettingsTextInput}
																		required
																		prepend="R"
																		disabled={
																			current_staff?.status === "Admin"
																				? false
																				: true
																		}
																	/>
																</div>
																<div className="w-1/2 space-y-6">
																	<Field
																		label="Rental"
																		name="rental"
																		type="text"
																		value={values.rental}
																		as={SettingsTextInput}
																		prepend="R"
																		disabled={
																			current_staff?.status === "Admin"
																				? false
																				: true
																		}
																	/>
																</div>
															</div>

															<div className="flex space-x-4 mb-6">
																<div className="w-1/2 space-y-6">
																	{/* {process.env.REACT_APP_COLOR == "QP" && (
                                    
                                  )} */}
																	<Field
																		label="Launch Day Price"
																		name="launch_day_price"
																		type="text"
																		value={values.launch_day_price}
																		as={SettingsTextInput}
																		prepend="R"
																		disabled={
																			current_staff?.status === "Admin"
																				? false
																				: true
																		}
																	/>
																</div>
															</div>

															<div className="flex space-x-4">
																<div className="w-1/2 space-y-6">
																	<Field
																		label="Comment"
																		name="comment"
																		type="text"
																		value={values.comment}
																		as={SettingsTextInput}
																		disabled={
																			current_staff?.status === "Admin"
																				? false
																				: true
																		}
																	/>
																</div>

																<div className="w-1/2 space-y-6">
																	<div className="text-center my-6">
																		Unit ID: {values.unit_id}
																	</div>
																</div>
															</div>

															{current_staff?.status === "Admin" && (
																<div className="flex space-x-6">
																	<SubmitButton
																		name="Save"
																		disabled={
																			!isValid || isSubmitting || !dirty
																		}
																		isSubmitting={isSubmitting}
																		text="Save"
																	/>
																	<CustomButton
																		type="secondary"
																		text="Close"
																		onClick={() => dispatch(closeModal())}
																	/>
																</div>
															)}
														</div>
														{/* )} */}

														<div className="w-1/2">
															<h2 className="text-lg font-medium text-gray-900 mb-4">
																Booking History
															</h2>

															{bookings?.map((b) => (
																<div className="mb-4">
																	<Link
																		to={
																			b.declined
																				? "/booking/declined?user_id=" +
																				b?.user.id +
																				"&booking_id=" +
																				b.id
																				: b.tag
																					? "/booking/management?tag=" +
																					b.tag +
																					"&user_id=" +
																					b?.user.id +
																					"&booking_id=" +
																					b.id
																					: "/booking/" +
																					b.location
																						?.toLowerCase()
																						.replace(/\s/g, "") +
																					"?user_id=" +
																					b?.user.id +
																					"&booking_id=" +
																					b.id
																		}
																		onClick={() => {
																			dispatch(getBooking(b.id));
																			dispatch(closeModal());
																		}}
																	>
																		<div key={b.id}>
																			{b.booking_reference} {b.user?.name}
																			<br/>
																			Booked: {b.created_at}
																			<br/>
																			Paid:{" "}
																			{b.rates?.length > 0
																				? b.rates[b.rates.length - 1].paid === 1
																					? "Yes"
																					: "No"
																				: "No"}
																			<br/>
																			Lease Start Date:{" "}
																			{b.rates[b.rates.length - 1] &&
																			b.rates[b.rates.length - 1].lease_start
																				? b.rates[b.rates.length - 1]
																					.lease_start
																				: lease_start_string}
																			<br/>
																			Lease End Date:{" "}
																			{b.rates[b.rates.length - 1] &&
																			b.rates[b.rates.length - 1].lease_end
																				? b.rates[b.rates.length - 1].lease_end
																				: lease_end_string}
																			<br/>
																			Lease Signed:{" "}
																			{b.rates?.length > 0
																				? b.rates[b.rates.length - 1]
																					.signed_lease_at !== ""
																					? b.rates[b.rates.length - 1]
																						.signed_lease_at
																					: "No"
																				: "No"}
																			<br/>
																		</div>
																	</Link>
																</div>
															))}
														</div>
														<div className="w-1/2">
															<h2 className="text-lg font-medium text-gray-900 mb-4">
																Waiting List
															</h2>
															{waitlist?.map((b) => (
																<div className="mb-4">
																	<Link
																		to={
																			b.waiting_bed_id
																				? "/booking/declined"
																				: b.tag
																					? "/booking/management?tag=" + b.tag
																					: "/booking/" +
																					b.location
																						?.toLowerCase()
																						.replace(/\s/g, "")
																		}
																		onClick={() => {
																			dispatch(getBooking(b.id));
																			dispatch(closeModal());
																		}}
																	>
																		<div key={b.id}>
																			{b.booking_reference} {b.user?.name}
																			<br/>
																			Booked: {b.created_at}
																			<br/>
																			Paid:{" "}
																			{b.rates?.length > 0
																				? b.rates[b.rates.length - 1].paid === 1
																					? "Yes"
																					: "No"
																				: "No"}
																			<br/>
																			Lease Start Date:{" "}
																			{b.rates[b.rates.length - 1] &&
																			b.rates[b.rates.length - 1].lease_start
																				? b.rates[b.rates.length - 1]
																					.lease_start
																				: lease_start_string}
																			<br/>
																			Lease End Date:{" "}
																			{b.rates[b.rates.length - 1] &&
																			b.rates[b.rates.length - 1].lease_end
																				? b.rates[b.rates.length - 1].lease_end
																				: lease_end_string}
																			<br/>
																			Lease Signed:{" "}
																			{b.rates?.length > 0
																				? b.rates[b.rates.length - 1]
																					.signed_lease_at !== ""
																					? b.rates[b.rates.length - 1]
																						.signed_lease_at
																					: "No"
																				: "No"}
																			<br/>
																		</div>
																	</Link>
																</div>
															))}
														</div>
													</div>
												</Form>
											)}
										</Formik>

										<p>
											<b>Unit Has Wifi: </b>{" "}
											{initialValues?.wifi !== null &&
											initialValues?.wifi.length > 0
												? "Yes"
												: "No"}
										</p>
										<hr/>
										{initialValues?.property?.wifi_property_id != null ? (
											<>
												{initialValues?.wifi !== null &&
												initialValues?.wifi.length > 0 ? (
													<>
														<p>
															<b>Wifi Details:</b>
														</p>
														{initialValues?.wifi.map((wifi) => (
															<div key={wifi.id}>
																<small>
																	<i>Type:</i>{" "}
																	{wifi.mac_address !== null
																		? "Device"
																		: "Account"}
																</small>
																<br/>
																<small>
																	<i>ID:</i> {wifi.wifi_id}
																</small>
																<br/>
																{wifi.mac_address !== null ? (
																	<>
																		<small>
																			<i>Name:</i> {wifi.name}
																		</small>
																		<br/>
																		<small>
																			<i>Mac Address:</i> {wifi.mac_address}
																		</small>
																		<br/>
																	</>
																) : (
																	<>
																		<small>
																			<i>Username:</i> {wifi.username}
																		</small>
																		<br/>
																		<small>
																			<i>Password:</i> {wifi.password}
																		</small>
																		<br/>
																	</>
																)}
																<hr/>
															</div>
														))}
													</>
												) : (
													<div className="w-1/3 mt-5">
														<CustomButton
															text="Create Wifi Account"
															styling="w-full mb-2"
															icon={faPlus}
															onClick={() =>
																createWifiAccount(initialValues.unit_id)
															}
															loading={wiFiLoading}
														/>
														<p>You cannot create a wifi device!</p>
													</div>
												)}
												<br/>
											</>
										) : (
											""
										)}
									</div>
								</div>
							</div>
						</ErrorBoundary>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
