import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faCommentLines, faEnvelope, faNote, faListDropdown, faPenField, faTimes, faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";

function typeSwitch(platform) {
    switch (platform) {
        case "whatsapp":
            return faWhatsapp;
        case "whatsapp bot":
            return faWhatsapp;
        case "email":
            return faEnvelope;
        case "sms":
            return faCommentLines;
        case "note":
            return faNote;
        case "audit":
            return faPenField;
        case "application":
            return faListDropdown;
        case "failed":
            return faTriangleExclamation;
        default:
            return faTimes;
    }
}
export default function TypeIcon({ platform, className }) {
    return <FontAwesomeIcon icon={typeSwitch(platform)} className={className} />;
}
