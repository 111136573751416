import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeftFromArc} from "@fortawesome/pro-solid-svg-icons";
import SettingsSelect from "../Forms/SettingsSelect";
import Select from "react-select";

export default function BookingBotFlow({current_staff, current_booking, ...props}) {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [botFlows, setBotFlows] = useState([]);
	const [years, setYears] = useState([]);
	const [currentBotFlow, setCurrentBotFlow] = useState(current_booking?.bot_flow_id);
	const [mainFlow, setMainFlow] = useState(null);
	const [subFlow, setSubFlow] = useState("");
	const [subFlows, setSubFlows] = useState([]);
	const [error, setError] = useState("");

	useEffect(() => {
		axios({
			method: "get",
			url: `${process.env.REACT_APP_API_URL}get_bot_flows`,
		})
			.then((response) => {
				setBotFlows(response.data[0]);
				setYears(response.data[1]);
				setLoading(false);
			})
			.catch((error) => {
				alert("Failed to get bot flows");
				setLoading(false);
			});
	}, []);

	async function getBookingFlowStatus() {
		await axios({
			method: "get",
			url: `${process.env.REACT_APP_API_URL}get_booking_flow_status/${current_booking?.id}`,
		})
			.then((response) => {
				setCurrentBotFlow(response.data);
			})
			.catch((error) => {
				alert("Failed to refresh booking flow status");
			});
	}

	// Find the matching parent or child flow based on currentBotFlow
	function findMatchingFlow() {
		let foundFlow = null;

		// First, search if currentBotFlow matches a parent flow
		foundFlow = botFlows.find((botFlow) => botFlow.id === currentBotFlow);

		// If not found, search within the child items of each parent flow
		if (!foundFlow) {
			botFlows.forEach((parentFlow) => {
				if (parentFlow.children) {
					const childMatch = parentFlow.children.find((child) => child.id === currentBotFlow);
					if (childMatch) {
						foundFlow = parentFlow; // Set the parent flow if child match is found
					}
				}
			});
		}

		return foundFlow;
	}

	const matchedFlow = findMatchingFlow();

	async function stopBotFlow() {
		setLoading(true);
		await axios({
			method: "put",
			url: `${process.env.REACT_APP_API_URL}stop_bot_flow/${current_booking.id}`,
			data: {
				current_staff_id: current_staff.id,
			},
		})
			.then((response) => {
				getBookingFlowStatus();
			})
			.catch((error) => {
				alert("error");
			})
			.finally(() => {
				// dispatch(getBooking());
				setLoading(false);
			});
	}

	// Handle the main flow selection
	const handleMainFlowChange = (e) => {
		const selectedFlowId = e.value;
		setMainFlow(selectedFlowId);

		// Find the selected main flow and get its children (sub-flows)
		const selectedFlow = botFlows.find((flow) => flow.id === parseInt(selectedFlowId));

		if (selectedFlow && selectedFlow.children) {
			setSubFlows(selectedFlow.children); // Update sub flows based on main flow
		} else {
			setSubFlows([]); // Reset if no sub flows
		}
	};

	// Handle the sub flow selection
	const handleSubFlowChange = (e) => {
		setSubFlow(e.target.value);
	};

	// Submit function with validation check
	const handleSubmit = async (e) => {
		e.preventDefault();

		// Check if main flow is selected
		if (!mainFlow) {
			setError("Please select a main flow before submitting.");
			return;
		}

		setLoading(true);
		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}start_bot_flow`,
			data: {
				current_staff_id: current_staff.id,
				booking_id: current_booking?.id,
				mainFlow: mainFlow,
				subFlow: subFlow,
			},
		})
			.then((response) => {
				getBookingFlowStatus();
			})
			.catch((error) => {
				alert("error");
			})
			.finally(() => {
				// dispatch(getBooking());
				setLoading(false);
			});
	};

	if (loading === true) {
		return <p>Loading...</p>;
	}

	return (
		<div>
			<div className="grid grid-cols-2 gap-4 mb-4">
				<button className="bg-primary text-white px-4 py-2 rounded-full" onClick={() => getBookingFlowStatus()}>
					Refresh
				</button>

				{currentBotFlow > 0 && (
					<button className="bg-primary text-white px-4 py-2 rounded-full" onClick={() => stopBotFlow()}>
						Stop
					</button>
				)}
			</div>

			{matchedFlow ? (
				<div>
					<ul>
						{/* Show the matched parent flow */}
						<li className="flex justify-between items-center" key={matchedFlow.id}>
							<div>{matchedFlow.name}</div>
							{/* Show status next to the parent if it matches */}
							{matchedFlow.id === currentBotFlow &&
								<FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true"
												 icon={faArrowLeftFromArc}/>}
						</li>

						{/* If the parent has children, loop through and display them */}
						{matchedFlow.children &&
							matchedFlow.children.map((childFlow) => (
								<li className="flex justify-between items-center ml-8" key={childFlow.id}>
									<div>{childFlow.name}</div>
									{/* Show status next to the child if it matches */}
									{childFlow.id === currentBotFlow &&
										<FontAwesomeIcon className="h-6 w-6 text-primary" aria-hidden="true"
														 icon={faArrowLeftFromArc}/>}
								</li>
							))}
					</ul>
				</div>
			) : (
				<div>
					<form onSubmit={handleSubmit}>
						{error && <p className="text-red-500 mb-4">{error}</p>}

						{/* Main Flow Dropdown */}
						<div>
							<label
								className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between"
								htmlFor="mainFlow">
								Main Flow:
							</label>
							<Select
								id="mainFlow"
								options={botFlows.map(flow => ({value: flow.id, label: flow.name}))}
								onChange={handleMainFlowChange}
								value={mainFlow ? {
									value: mainFlow,
									label: botFlows.find(flow => flow.id === mainFlow)?.name
								} : null}
								components={{
									IndicatorSeparator: () => null,
								}}
								className="mb-4 mt-1 block font-light border-gray-300 rounded-lg shadow-sm focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
							/>
						</div>

						{/* Sub Flow Dropdown */}
						{subFlows.length > 0 && (
							<div>
								<label
									className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between"
									htmlFor="subFlow">
									Sub Flow:
								</label>
								<select
									className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
									id="subFlow"
									value={subFlow}
									onChange={handleSubFlowChange}
								>
									<option value="">Select Sub Flow</option>
									{subFlows.map((subFlow) => (
										<option key={subFlow.id} value={subFlow.id}>
											{subFlow.name}
										</option>
									))}
								</select>
							</div>
						)}

						{/* Submit Button */}
						<div>
							<button type="submit" className="bg-primary text-white px-4 py-2 rounded-full mt-4">
								Start Flow
							</button>
						</div>
					</form>
				</div>
			)}
		</div>
	);
}
