import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faCircleXmark, faLoader } from "@fortawesome/pro-solid-svg-icons";
import { openModal } from "../../store/modal/modalReducer";
import ErrorBoundary from "../../containers/ErrorBoundary";

import CustomButton from "../Helpers/CustomButton";
import { closeModal } from "../../store/modal/modalReducer";
import FileUpload from "../Forms/FileUpload";
import FileDisplay from "../Forms/FileDisplay";
import { getBooking } from "../../store/booking/bookingActions";
import axios from "axios";
import ViewFile from "../Helpers/ViewFile";

export default function SlideoverFiles(props) {
  const dispatch = useDispatch();
  const { current_booking } = useSelector((state) => state.booking);
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  const [showFile, setShowFile] = useState(false);
  const [fileId, setFileId] = useState(false);
  const [fileView, setFileView] = useState(false);
  const [untaggedFiles, setUntaggedFiles] = useState([]);

  useEffect(() => {
    // setLoading(true);
    // dispatch(getBooking(current_booking.id));
    setLoading(true);

    if (fileId) {
      setFileView([]);
      //   setFileId(false);
      setShowFile(false);

      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}preview_file`,
        data: { fileId: fileId },
      })
        .then((response) => {
          // console.log(response.data)
          setFileView(response.data);
          setShowFile(true);
        })
        .catch(() => {
          setFileView([]);
          setFileId(false);
          setShowFile(false);
        });
    }
  }, [setLoading, fileId]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => {
          dispatch(closeModal());
          setOpen(false);
        }}
      >
        <ErrorBoundary>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel
                    className={
                      showFile === true && fileView
                        ? "pointer-events-auto w-screen max-w-6xl"
                        : "pointer-events-auto w-screen max-w-md"
                    }
                  >
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll bg-light_background">
                        <div className="bg-dark_background py-6">
                          <div className=" px-4 sm:px-6">
                            <div className="flex items-start justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                Attachments for {current_booking.user.name}{" "}
                                {current_booking.user.surname}
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md text-white hover:text-gray-200"
                                  onClick={() => dispatch(closeModal())}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <FontAwesomeIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                    icon={faTimes}
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex">
                          {showFile === true && fileView && (
                            <div className="max-w-2xl hidden md:block">
                              {!fileView.encrypted ? (
                                <div>
                                  <FileDisplay
                                    file={fileView}
                                    key={fileView.id}
                                    callback={true}
                                    onScreen={true}
                                  />
                                  {fileView &&
                                    fileView.type &&
                                    fileView.type.includes("pdf") && (
                                      <ViewFile file={fileView} />
                                    )}
                                  {fileView &&
                                    fileView.type &&
                                    fileView.type.includes("image") && (
                                      <img
                                        src={`data:image/jpg;base64,${fileView.content}`}
                                        alt="image"
                                      />
                                    )}

                                  {!fileView.type.includes("image") &&
                                    !fileView.type.includes("pdf") && (
                                      <div className="flex items-center bg-red-100 rounded-md p-4">
                                        <FontAwesomeIcon
                                          className="h-4 w-4 text-red-800 mr-4"
                                          icon={faCircleXmark}
                                        />

                                        {fileView.type.includes("null") ? (
                                          <>
                                            <p className="text-red-800">
                                              This file is corrupted.
                                            </p>
                                            <p className="text-red-800">
                                              {" "}
                                              Please request a new file and
                                              delete this one
                                            </p>
                                          </>
                                        ) : (
                                          <>
                                            <p className="text-red-800">
                                              Format cannot be translated.
                                            </p>
                                            <p className="text-red-800">
                                              {" "}
                                              Please download the file.
                                            </p>
                                          </>
                                        )}
                                      </div>
                                    )}
                                </div>
                              ) : (
                                <>
                                  <FileDisplay
                                    file={fileView}
                                    key={fileView.id}
                                    callback={true}
                                    onScreen={true}
                                  />
                                  <div className="flex items-center bg-red-100 rounded-md p-4">
                                    <FontAwesomeIcon
                                      className="h-4 w-4 text-red-800 mr-4"
                                      icon={faCircleXmark}
                                    />
                                    <p className="text-red-800">
                                      The File is Encrypted. Please download the
                                      file and use the password.
                                    </p>
                                  </div>
                                </>
                              )}
                            </div>
                          )}

                          <div className="relative mt-6 flex-1 px-4 sm:px-6">
                            <FileUpload
                              current_booking={current_booking}
                              file_type=""
                              type={props.type}
                              tag_id={props.tag_id}
                            />
                            <div className="relative mt-6 py-4">
                              <div
                                className="absolute inset-0 flex items-center"
                                aria-hidden="true"
                              >
                                <div className="w-full border-t border-dark_background" />
                              </div>
                              <div className="relative flex justify-center">
                                <span className="px-3 bg-light_background text-lg font-medium text-gray-600">
                                  Uploaded Files
                                </span>
                              </div>
                            </div>

                            {current_booking.files.length > 0 ||
                            current_booking.untagged_files.length > 0 ? (
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <CustomButton
                                  text="View All Files"
                                  styling="mx-auto mb-6"
                                  onClick={() =>
                                    dispatch(
                                      openModal({
                                        modal_type: "ModalViewFiles",
                                        modal_props: {
                                          files: current_booking.files,
                                        },
                                      })
                                    )
                                  }
                                />
                                <p className="text-lg">Untracked Files</p>
                                <br />
                                <ul
                                  role="list"
                                  className="border border-dark_background rounded-md divide-y divide-dark_background"
                                >
                                  {current_booking.untagged_files.map(
                                    (file) => (
                                      <FileDisplay
                                        file={file}
                                        key={file.id}
                                        setShowFile={setShowFile}
                                        showFile={showFile}
                                        setFileId={setFileId}
                                        fileId={fileId}
                                        onScreen={false}
                                        currentPreview={fileId}
                                        mapped={false}
                                        moveYear={current_booking?.year}
                                      />
                                    )
                                  )}
                                </ul>
                                <br />
                                <p className="text-lg">
                                  {current_booking?.year.year} Files
                                </p>
                                <br />
                                <ul
                                  role="list"
                                  className="border border-dark_background rounded-md divide-y divide-dark_background"
                                >
                                  {current_booking.files.map((file) => (
                                    // <FileDisplay file={file} key={file.id} />
                                    <FileDisplay
                                      file={file}
                                      key={file.id}
                                      setShowFile={setShowFile}
                                      showFile={showFile}
                                      setFileId={setFileId}
                                      fileId={fileId}
                                      onScreen={false}
                                      currentPreview={fileId}
                                    />
                                  ))}
                                </ul>
                              </dd>
                            ) : (
                              <p>No files have been uploaded</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </ErrorBoundary>
      </Dialog>
    </Transition.Root>
  );
}
