import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {Link} from "react-router-dom";
import {openModal} from "../../store/modal/modalReducer";
import {faTrash} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function LinkedChats({
										current_booking,
										setIgnoreRefresh,
										linkedChats,
										setLinkedChats,
										setLinkedLoading
									}) {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const {current_staff} = useSelector((state) => state.auth);

	async function deleteLink(e, id) {
		e.preventDefault();

		if (window.confirm("Are you sure you want to delete this link?")) {
			await axios({
				method: "delete",
				url: `${process.env.REACT_APP_API_URL}delete_linked_chat/${id}`,
			})
				.then((response) => {
					axios({
						method: "get",
						url: `${process.env.REACT_APP_API_URL}linked_chats/${current_booking.id}`,
					})
						.then((response) => {
							setLinkedChats(response.data);
							setLoading(false);
						})
						.catch((error) => {
							alert("Failed to get linked chats");
							setLoading(false);
						});
				})
				.catch((error) => {
					alert("Failed to delete link");
				});
		}
	}

	return (
		<div>
			<button
				className="bg-primary text-white px-4 py-2 rounded-full w-full mb-4"
				onClick={() => dispatch(openModal({
					modal_type: "ModalLinkChat",
					modal_props: {
						current_booking: current_booking,
						setLinkedLoading: setLinkedLoading
					}
				}))}
			>
				Link a Chat
			</button>

			{linkedChats.length > 0 ? (
				<div>
					{linkedChats.map((chat) => (
						<div key={chat.id}>
							<Link
								className="flex justify-between items-center hover:bg-gray-200 hover:border-gray-200 cursor-pointer px-4 py-2"
								to={
									chat?.user_id == current_booking?.user?.id
										? "/booking/" + chat?.linked_booking?.location.toLowerCase().replace(/\s+/g, "") + "?user_id=" + chat?.linked_user_id + "&booking_id=" + chat?.linked_booking_id
										: "/booking/" + chat?.booking?.location.toLowerCase().replace(/\s+/g, "") + "?user_id=" + chat?.user_id + "&booking_id=" + chat?.booking_id
								}
							>
								<div>{chat?.user_id == current_booking?.user?.id ? chat?.linked_user?.name : chat?.user?.name}</div>
								<div>
									<span>{chat?.user_id == current_booking?.user?.id ? chat?.linked_type : chat?.type}</span>

									{current_staff?.status == "Admin" && (
										<button type="button" onClick={(e) => deleteLink(e, chat.id)}>
											<FontAwesomeIcon icon={faTrash} className="h-4 w-4 ml-4 text-primary"/>
										</button>
									)}
								</div>
							</Link>
						</div>
					))}
				</div>
			) : (
				<p>No linked chats found.</p>
			)}
		</div>
	);
}
