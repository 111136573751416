import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import NotificationToast from "../Helpers/NotificationToast";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTimes,
    faEye,
    faPaperclip,
    faEnvelopesBulk,
    faInfoCircle,
    faCommentSmile,
    faCommentXmark,
    faRobot,
    faLocationCheck,
    faPencil,
    faCheck,
    faImageSlash,
    faTrash,
    faDownload,
    faSackDollar,
    faHandHoldingDollar,
    faLocationXmark,
} from "@fortawesome/pro-regular-svg-icons";
import { faFile, faFileCsv, faFileExcel, faFilePdf, faGif, faImage, faMicrophone } from "@fortawesome/pro-solid-svg-icons";
import { getBooking } from "../../store/booking/bookingActions";
import { faFileZipper, faLoader, faVideo } from "@fortawesome/pro-regular-svg-icons";
import { closeModal, openModal } from "../../store/modal/modalReducer";
import { toast } from "react-toastify";

export default function FileDisplay(props) {
    let file = props.file;
    let callback = props.callback;
    let currentPreview = props.currentPreview;
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { current_booking } = useSelector((state) => state.booking);
    const { settings, years } = useSelector((state) => state.settings);
    const { current_staff, staff } = useSelector((state) => state.auth);
    let icon = faFile;

    switch (file.extension) {
        case "pdf":
            icon = faFilePdf;
            break;
        case "csv":
            icon = faFileCsv;
            break;
        case "xsl":
        case "xlsx":
            icon = faFileExcel;
            break;
        case "jpg":
        case "png":
        case "jpeg":
            icon = faImage;
            break;
        case "gif":
            icon = faGif;
            break;
        case "zip":
        case "tar":
            icon = faFileZipper;
        case "mp4":
            icon = faVideo;
            break;
        case "mp3":
            icon = faMicrophone;
            break;
        default:
            icon = faFile;
            break;
    }

    async function toggle_file(new_status) {
        setLoading(true);
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}toggle_file`,
            data: { id: file.id, status: new_status },
        })
            .then(() => {
                dispatch(getBooking(current_booking.id));
            })
            .catch((response) => {
                <NotificationToast title={"Something went wrong"} message={response.message} type="error" />;
            })
            .finally(() => {
                setLoading(false);

                if (callback) {
                    dispatch(closeModal());
                    dispatch(
                        openModal({
                            modal_type: "ModalViewFiles",
                            modal_props: {
                                files: current_booking.files,
                            },
                        })
                    );
                }
            });
    }

    async function download_file(file) {
        setLoading(true);
        await axios({
            method: "get",
            responseType: "blob",
            url: `${process.env.REACT_APP_API_URL}download_file/${file.id}`,
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", file.name + "." + file.extension);
                document.body.appendChild(link);
                link.click();
            })
            .catch(function (error) {
                toast(<NotificationToast title="File Missing" message={"Something went wrong, we could not download this file!"} type="error" />);
            })
            .finally(() => setLoading(false));
    }

    async function delete_file(id) {
        setLoading(true);
        await axios({
            method: "delete",
            url: `${process.env.REACT_APP_API_URL}delete_file_admin/${id}`,
        })
            .then((response) => {
                dispatch(getBooking(current_booking.id));
            })
            .finally(() => {
                setLoading(false);
                if (callback) {
                    dispatch(closeModal());
                    dispatch(
                        openModal({
                            modal_type: "ModalViewFiles",
                            modal_props: {
                                files: current_booking.files,
                            },
                        })
                    );
                }
            });
    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    async function make_payment(file_id) {
        setLoading(true);
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}mark_payment/${file_id}`,
        })
            .then((response) => {
                dispatch(getBooking(current_booking.id));
            })
            .finally(() => {
                setLoading(false);
            });
    }

    async function make_pop(file_id) {
        setLoading(true);
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}mark_pop/${file_id}`,
        })
            .then((response) => {
                dispatch(getBooking(current_booking.id));
            })
            .finally(() => {
                setLoading(false);
            });
    }

    async function map_file(file_id, year_id) {
        setLoading(true);
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}mark_tag/${file_id}/${year_id}`,
        })
            .then((response) => {
                dispatch(getBooking(current_booking.id));
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
            {loading ? (
                <FontAwesomeIcon icon={faLoader} className="mx-auto text-gray-600 text-lg animate-spin" />
            ) : (
                <Formik
                    initialValues={{
                        name: file.name,
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(true);
                        setLoading(true);

                        await axios({
                            method: "put",
                            url: `${process.env.REACT_APP_API_URL}rename_file/${file.id}`,
                            data: {
                                name: values.name,
                            },
                        })
                            .then((response) => {
                                dispatch(getBooking(current_booking.id));
                                toast(<NotificationToast title="File Name Updated Succesfully" message={"Name changed to " + response.data} />);
                            })
                            .catch((error) => {
                                toast(<NotificationToast title="Something went wrong" message={error.response} type="error" />);
                            })
                            .finally(() => {
                                setTimeout(() => {
                                    setLoading(false);
                                }, 1000);

                                if (callback) {
                                    dispatch(closeModal());
                                    dispatch(
                                        openModal({
                                            modal_type: "ModalViewFiles",
                                            modal_props: {
                                                files: current_booking.files,
                                            },
                                        })
                                    );
                                }
                            });
                    }}
                >
                    {({ handleSubmit, values }) => (
                        <Form onSubmit={handleSubmit} className="w-full flex">
                            {props && !props.onScreen && (
                                <FontAwesomeIcon
                                    icon={faEye}
                                    onClick={() => {
                                        props.setShowFile(true);
                                        props.setFileId(file.id);
                                    }}
                                    className={
                                        currentPreview === file.id
                                            ? "text-primary hover:text-primary cursor-pointer mr-5 hidden md:block"
                                            : "text-gray-400 hover:text-gray-800 cursor-pointer mr-5 hidden md:block"
                                    }
                                    title="Preview File"
                                />
                            )}
                            <div className="w-0 flex-1 flex items-center">
                                <FontAwesomeIcon className="flex-shrink-0 h-5 w-5 text-gray-600" icon={icon} aria-hidden="true" />
                                <Field
                                    className={currentPreview === file.id ? "ml-2 flex-1 w-0 truncate bg-transparent text-primary" : "ml-2 flex-1 w-0 truncate text-gray-600 bg-transparent"}
                                    name="name"
                                    placeholder={file.name}
                                    title={file.name}
                                />
                            </div>
                            <div className="ml-4 flex-shrink-0 space-x-2 flex items-center">
                                {props.mapped === false ? (
                                    <div className="flex">
                                        <div className="font-medium text-gray-400 hover:text-gray-800 cursor-pointer" onClick={() => map_file(file.id, props.moveYear.id)}>
                                            <FontAwesomeIcon icon={faLocationCheck} title="Map File" /> &nbsp;
                                            <span>To {props.moveYear.year}</span>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {file && file.rename === 1 && current_staff?.hideAppInfoUpdate != 1 ? (
                                            <FontAwesomeIcon icon={faPencil} onClick={() => handleSubmit()} className="text-gray-400 hover:text-gray-800 cursor-pointer" title="Rename File" />
                                        ) : (
                                            ""
                                        )}
                                        {current_staff?.hideAppInfoUpdate != 1 && (
                                            <>
                                                <FontAwesomeIcon
                                                    icon={faCheck}
                                                    onClick={() => toggle_file("Approved")}
                                                    className={classNames(file.status === "Approved" ? "text-green-600 font-bold" : "", "text-gray-400 hover:text-gray-800 cursor-pointer")}
                                                    title="Approve File"
                                                />
                                                <FontAwesomeIcon
                                                    icon={faTimes}
                                                    onClick={() => toggle_file("Declined")}
                                                    className={classNames(file.status === "Declined" ? "text-red-600" : "", "text-gray-400 hover:text-gray-800 cursor-pointer")}
                                                    title="Decline File"
                                                />
                                                <FontAwesomeIcon
                                                    icon={faImageSlash}
                                                    onClick={() => toggle_file("Ignored")}
                                                    className={classNames(file.status === "Ignored" ? "text-yellow-600" : "", "text-gray-400 hover:text-gray-800 cursor-pointer")}
                                                    title="Ignore File (best used for email signatures)"
                                                />
                                                <FontAwesomeIcon icon={faTrash} onClick={() => delete_file(file.id)} className="text-gray-400 hover:text-gray-800 cursor-pointer" title="Delete File" />
                                            </>
                                        )}
                                        {current_staff?.hideAppInfoUpdate != 1 && (
                                            <>
                                                <FontAwesomeIcon
                                                    icon={faDownload}
                                                    onClick={() => download_file(file)}
                                                    className="font-medium text-gray-400 hover:text-gray-800 cursor-pointer"
                                                    title="Download File"
                                                />
                                                <FontAwesomeIcon
                                                    icon={faSackDollar}
                                                    onClick={() => make_payment(file.id)}
                                                    className={classNames(file.file_type === "Payment" ? "text-green-600 font-bold" : "", "text-gray-400 hover:text-gray-800 cursor-pointer")}
                                                    title="Tag Payment"
                                                />
                                                <FontAwesomeIcon
                                                    icon={faHandHoldingDollar}
                                                    onClick={() => make_pop(file.id)}
                                                    className={classNames(file.file_type === "POP" ? "text-green-600 font-bold" : "", "text-gray-400 hover:text-gray-800 cursor-pointer")}
                                                    title="Tag POP"
                                                />
                                                {file.year_id != settings.year.id && (
                                                    <>
                                                        <div className="font-medium text-gray-400 hover:text-gray-800 cursor-pointer" onClick={() => map_file(file.id, settings.year.id)}>
                                                            <FontAwesomeIcon icon={faLocationCheck} title="Map File" /> &nbsp;
                                                            <span>To {settings.year.year}</span>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </li>
    );
}
