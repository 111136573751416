import React from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import {Field, Form, Formik} from "formik";
import {faSave} from "@fortawesome/pro-regular-svg-icons";
import SubmitButton from "../../components/Forms/SubmitButton";
import QuickFormText from "../../components/Forms/QuickFormText";
import QuickFormSelect from "../../components/Forms/QuickFormSelect";

export default function AdminUploadsPage() {
	return (
		<div className="px-1 sm:px-4 my-2 xl:my-0 xl:p-4 h-screen w-full">
			<div className="flex justify-between mb-8 items-center">
				<h1 className="text-lg font-bold">Uploads</h1>

				<div>
					<Link to="/settings_new" className="bg-primary text-white px-4 py-2 rounded-full">
						Back to Settings
					</Link>
				</div>
			</div>

			<Formik
				enableReinitialize
				initialValues={{
					display_name: "",
					file: [],
					file_type: 0,
				}}
				onSubmit={async (values, {setSubmitting, setErrors, resetForm}) => {
					const formData = new FormData();
					for (const key in values) {
						if (values.hasOwnProperty(key)) {
							formData.append(key, values[key]);
						}
					}

					// Append file if it exists
					// if (values.file) {
					//     formData.append("file", values.file);
					// }

					await axios({
						method: "post",
						url: `${process.env.REACT_APP_API_URL}upload_resource_file`,
						data: formData,
					})
						.then((resp) => {
							console.log(resp);
							// axios({
							// 	method: "get",
							// 	url: `${process.env.REACT_APP_API_URL}get_bot_flows`,
							// })
							// 	.then((response) => {
							// 		setBotFlows(response.data[0]);
							// 		setYears(response.data[1]);
							// 		setLoading(false);
							// 	})
							// 	.catch((error) => {
							// 		alert("Failed to get bot flows");
							// 		setLoading(false);
							// 	});
						})
						.catch((error) => {
							console.log(error);
							console.log(error.response);
						})
						.finally(() => {
							setSubmitting(false);
						});

					setSubmitting(false);
				}}
			>
				{({values, errors, setFieldValue, handleSubmit, isValid, isSubmitting, dirty}) => (
					<Form onSubmit={handleSubmit} className="grid grid-cols-4 gap-2">
						<div>
							<label className="text-sm text-dark_background">File to Upload</label>
							<input
								id="file"
								name="file"
								type="file"
								onChange={(event) => {
									setFieldValue("file", event.currentTarget.files[0]);
								}}
							/>
						</div>

						<Field name="display_name" as={QuickFormText} label="Display Name"/>

						<QuickFormSelect
							label="File Type"
							name="file_type"
							onChange={setFieldValue}
							value={values.file_type}
							error={errors.file_type}
							options={[
								{value: 0, label: "All"},
								{value: 1, label: "Approved Template"},
								{
									value: 2,
									label: "Un-Approved Template",
								},
								{
									value: 3,
									label: "Resource",
								}, {
									value: 4,
									label: "Document",
								},
							]}
						/>

						<SubmitButton name="Save" disabled={!isValid || isSubmitting || !dirty}
									  isSubmitting={isSubmitting} icon={faSave} text="Upload" style={{width: "100%"}}/>
					</Form>
				)}
			</Formik>

			<div className="mt-8">table</div>
		</div>
	);
}
