import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { faFileInvoiceDollar, faFileSignature, faSave, faSquareEnvelope, faTimes, faTreasureChest } from "@fortawesome/pro-regular-svg-icons";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import NotificationToast from "../../components/Helpers/NotificationToast";
import { openModal } from "../../store/modal/modalReducer";
import { Formik, Form, Field } from "formik";
import SubmitButton from "../Forms/SubmitButton";
import CustomButton from "../Helpers/CustomButton";
import { getBooking } from "../../store/booking/bookingActions";
import QuickFormText from "../Forms/QuickFormText";
import QuickFormSelect from "../Forms/QuickFormSelect";
import LoadingComponent from "../Helpers/LoadingComponent";
import QuickFormDate from "../Forms/QuickFormDate";
import SettingsSwitch from "../Forms/SettingsSwitch";
import moment from "moment";
import SettingsSelect from "../Forms/SettingsSelect";

export default function GridInvoiceInformation({ setDoc, db, setIgnoreRefresh }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [booking, setBooking] = useState([]);
    const { current_booking } = useSelector((state) => state.booking);
    const { current_staff } = useSelector((state) => state.auth);
    const [units, setUnits] = useState([]);
    const [beds, setBeds] = useState([]);
    const [unitTypes, setUnitTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [invoiceButtonLoading, setInvoiceButtonLoading] = useState(false);
    const [nsfasButtonLoading, setNsfasButtonLoading] = useState(false);
    const [emailButtonLoading, setEmailButtonLoading] = useState(false);
    var invoiceButtonLoadingText = "Generating Invoice";
    var emailButtonLoadingText = "Sending Invoice and Lease";
    var leaseButtonLoadingText = "Generating Lease";
    var suretyButtonLoadingText = "Generating Surety";
    const [leaseButtonLoading, setLeaseButtonLoading] = useState(false);
    const [suretyButtonLoading, setSuretyButtonLoading] = useState(false);
    const [leaseAdButtonLoading, setLeaseAdButtonLoading] = useState(false);
    const { properties } = useSelector((state) => state.properties);

    const { type } = useParams();
    var url_string = window.location.href;
    var url = new URL(url_string);
    var tag_id = url.searchParams.get("tag");

    var month_strt = moment().add(1, "M").format("MM");
    var year_strt = moment().format("Y");

    if (process.env.REACT_APP_COLOUR === "QL") {
        if (moment().format("DD") < "09") {
            month_strt = moment().format("MM");
        }
    }

    const leaseTerms = [
        { value: 0, label: "" },
        { value: 1, label: "Flexi Lease" },
        { value: 2, label: "1 Year" },
        { value: 3, label: "2 Years" },
        { value: 4, label: "3 Years" },
        { value: 5, label: "4 Years" },
        { value: 6, label: "5+ Years" },
    ];

    const depositList = [
        { value: 0, label: "" },
        { value: 1, label: "Flexi / 1 Year Lease = 1 Month Rent" },
        { value: 2, label: "2+ Years Lease = 2 Months Exit Rent" },
    ];

    var lease_start_string = year_strt + "-" + month_strt + "-01";

    var month_end = moment().format("MM");
    var year_end = moment().add(1, "Y").format("Y");

    var lease_end_string = moment(new Date(year_end, month_end, 0)).format("Y-MM-D");

    if (process.env.REACT_APP_COLOUR === "UC") {
        lease_start_string = "2025-02-01";
        lease_end_string = "2025-11-30";
    }

    useEffect(async () => {
        getLocalBooking();
        if (current_booking.property_id) {
            getUnits(current_booking.property_id);
            getUnitTypes(current_booking.property_id);
        }
    }, [setBooking]);

    async function getLocalBooking() {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}user_profile/${current_booking.user.id}`,
        }).then((response) => {
            setBooking(response.data);
            setLoading(false);
        });
    }

    async function getUnits(id) {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}units/${id}`,
        }).then((response) => {
            setUnits(response.data);
        });
    }

    // async function getUnits(id) {
    //     await axios({
    //         method: "get",
    //         url: `${process.env.REACT_APP_API_URL}beds/${unit_id}`,
    //     }).then((response) => {
    //         setUnits(response.data);
    //     });
    // }

    async function getUnitTypes(id) {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}unit_types/${id}`,
        }).then((response) => {
            setUnitTypes(response.data);
        });
    }

    if (loading === true) {
        return <LoadingComponent />;
    }

    function formatToFloat(value) {
        if (value) {
            return parseFloat(value.replace(/,/g, ""));
        } else {
            return value;
        }
    }

    function email_documents() {
        var button = setEmailButtonLoading;
        button(true);
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}email_documents/${current_booking.id}`,
            data: { current_staff: current_staff },
        })
            .then((response) => {
                toast(<NotificationToast title={"Documents emailed"} message={"The email has sent succesfully."} />);
            })
            .catch((response) => {
                toast(<NotificationToast title={"Email failed"} message={response.response.data} type="error" />);
            })
            .finally(() => {
                button(false);
            });
    }

    async function re_generate_addendum(booking_id) {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}regen_addendum/${current_booking.id}`,
        })
            .then((response) => {
                toast(<NotificationToast title={type + " generated"} message={"You can now send the link to the applicant."} />);
                setIgnoreRefresh(true);
                dispatch(getBooking(current_booking.id));
            })
            .catch((response) => {
                toast(<NotificationToast title={type + " failed"} message={response.response.data} list={true} type="error" />);
            });
    }

    async function generate_document(type) {
        let type2 = type;
        if (type === "invoice") {
            var button = setInvoiceButtonLoading;
        } else if (type === "lease" || type === "lease_send") {
            var button = setLeaseButtonLoading;
            type = "lease";
        } else if (type === "surety") {
            var button = setSuretyButtonLoading;
        } else {
            var button = setLeaseAdButtonLoading;
        }

        button(true);

        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}generate_document/${type}/${current_booking.id}`,
        })
            .then((response) => {
                toast(<NotificationToast title={type + " generated"} message={"You can now send the link to the applicant."} />);
                // setIgnoreRefresh(true);
                // dispatch(getBooking(current_booking.id));

                //Send lease?
                if (type2 === "lease_send") {
                    axios({
                        method: "post",
                        url: `${process.env.REACT_APP_API_URL}send_lease/${current_booking?.user?.id}/${current_booking.id}`,
                    })
                        .then((response) => {
                            toast(<NotificationToast title={"Lease sent"} message={"The lease has been sent."} />);
                        })
                        .catch((response) => {
                            toast(<NotificationToast title={"Lease failed"} message={response.response.data} list={true} type="error" />);
                        });
                }
            })
            .catch((response) => {
                toast(<NotificationToast title={type + " failed"} message={response.response.data} list={true} type="error" />);
            })
            .finally(() => {
                button(false);
            });
    }

    var property_default_values = require("../../data/property_default_values.json");

    var default_values = 0;
    if (current_booking.property_id) {
        default_values = property_default_values.find((p) => p.id === current_booking.property_id);
    }

    async function make_nsfas_pricing() {
        setNsfasButtonLoading(true);
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}update_nsfas_pricing`,
            data: {
                current_staff: current_staff,
                current_booking_id: current_booking.id,
                user_id: current_booking?.user?.id,
            },
        })
            .then((response) => {
                toast(<NotificationToast title={"Pricing updated"} message={"The pricing has been updated succesfully."} />);
                setIgnoreRefresh(true);
                dispatch(getBooking(current_booking.id));
            })
            .catch((response) => {
                toast(<NotificationToast title={"Pricing Failed failed"} message={response.response.data} type="error" />);
            })
            .finally(() => {
                setNsfasButtonLoading(false);
            });
    }

    return (
        <div className="h-96 overflow-y-scroll scroll-smooth">
            {/* Start Information */}
            <Formik
                enableReinitialize
                initialValues={{
                    property_id: current_booking.property_id,
                    unit_type: current_booking.unit_type && current_booking.unit_type.id ? current_booking.unit_type.id : current_booking.unit_type,
                    unit_id: current_booking.unit_id,
                    joint_application: current_booking.joint_application,

                    invoice_monthly_total:
                        current_booking.rates[current_booking.rates.length - 1] && current_booking.rates[current_booking.rates.length - 1].invoice_monthly_total
                            ? current_booking.rates[current_booking.rates.length - 1].invoice_monthly_total
                            : "",
                    carport: current_booking.rates[current_booking.rates.length - 1] ? current_booking.rates[current_booking.rates.length - 1].carport : "",
                    invoice_monthly_hide:
                        current_booking.rates[current_booking.rates.length - 1] && current_booking.rates[current_booking.rates.length - 1].invoice_monthly_hide
                            ? current_booking.rates[current_booking.rates.length - 1].invoice_monthly_hide
                            : "",
                    // parking_fee: default_values?.parking_fee ? default_values.parking_fee : "",
                    parking_fee:
                        current_booking.rates && current_booking.rates.length > 0 && current_booking.rates[current_booking.rates.length - 1].parking_fee
                            ? current_booking.rates[current_booking.rates.length - 1].parking_fee
                            : default_values?.parking_fee
                            ? default_values.parking_fee
                            : "",
                    monthly_rental:
                        current_booking.rates[current_booking.rates.length - 1] && current_booking.rates[current_booking.rates.length - 1].monthly_rental
                            ? current_booking.rates[current_booking.rates.length - 1].monthly_rental
                            : "",
                    monthly_rental_wording: current_booking.rates[current_booking.rates.length - 1] ? current_booking.rates[current_booking.rates.length - 1].monthly_rental_wording : "",
                    escalation:
                        current_booking.rates[current_booking.rates.length - 1] && current_booking.rates[current_booking.rates.length - 1].escalation
                            ? current_booking.rates[current_booking.rates.length - 1].escalation
                            : "",
                    lease_term:
                        current_booking.rates[current_booking.rates.length - 1] && current_booking.rates[current_booking.rates.length - 1].lease_term
                            ? current_booking.rates[current_booking.rates.length - 1].lease_term
                            : 0,
                    deposit_type:
                        current_booking.rates[current_booking.rates.length - 1] && current_booking.rates[current_booking.rates.length - 1].deposit_type
                            ? current_booking.rates[current_booking.rates.length - 1].deposit_type
                            : 0,
                    admin_fee:
                        current_booking.rates[current_booking.rates.length - 1] && current_booking.rates[current_booking.rates.length - 1]
                            ? current_booking.rates[current_booking.rates.length - 1].admin_fee
                            : "",
                    deposit_price:
                        current_booking.rates[current_booking.rates.length - 1] && current_booking.rates[current_booking.rates.length - 1].deposit_price
                            ? current_booking.rates[current_booking.rates.length - 1].deposit_price
                            : "",
                    surance_amount:
                        current_booking.rates[current_booking.rates.length - 1] && current_booking.rates[current_booking.rates.length - 1].surance_amount
                            ? current_booking.rates[current_booking.rates.length - 1].surance_amount
                            : "",
                    booking_fee_price:
                        current_booking.rates[current_booking.rates.length - 1] && current_booking.rates[current_booking.rates.length - 1].booking_fee_price
                            ? current_booking.rates[current_booking.rates.length - 1].booking_fee_price
                            : "",
                    key_deposit: current_booking.rates[current_booking.rates.length - 1] ? current_booking.rates[current_booking.rates.length - 1].key_deposit : "",
                    carport: current_booking.rates[current_booking.rates.length - 1] ? current_booking.rates[current_booking.rates.length - 1].carport : "",
                    special_wording: current_booking.rates[current_booking.rates.length - 1] ? current_booking.rates[current_booking.rates.length - 1].special_wording : "",
                    // effluent: default_values?.effluent ? default_values.effluent : "",
                    effluent:
                        current_booking.rates && current_booking.rates.length > 0 && current_booking.rates[current_booking.rates.length - 1].effluent
                            ? current_booking.rates[current_booking.rates.length - 1].effluent
                            : default_values?.effluent
                            ? default_values.effluent
                            : "",
                    lease_surance: current_booking.rates[current_booking.rates.length - 1] ? current_booking.rates[current_booking.rates.length - 1].lease_surance : "",
                    // refuse: default_values?.refuse ? default_values.refuse : "",
                    refuse:
                        current_booking.rates && current_booking.rates.length > 0 && current_booking.rates[current_booking.rates.length - 1].refuse
                            ? current_booking.rates[current_booking.rates.length - 1].refuse
                            : default_values?.refuse
                            ? default_values.refuse
                            : "",
                    special_deduction: current_booking.rates[current_booking.rates.length - 1] ? current_booking.rates[current_booking.rates.length - 1].special_deduction : "",
                    lease_start:
                        current_booking.rates[current_booking.rates.length - 1] && current_booking.rates[current_booking.rates.length - 1].lease_start
                            ? current_booking.rates[current_booking.rates.length - 1].lease_start
                            : lease_start_string,
                    lease_end:
                        current_booking.rates[current_booking.rates.length - 1] && current_booking.rates[current_booking.rates.length - 1].lease_end
                            ? current_booking.rates[current_booking.rates.length - 1].lease_end
                            : lease_end_string,
                    occupation_date:
                        current_booking.rates[current_booking.rates.length - 1] && current_booking.rates[current_booking.rates.length - 1].occupation_date
                            ? current_booking.rates[current_booking.rates.length - 1].occupation_date
                            : "",
                    size_required: current_booking.user.premises ? current_booking.user.premises.size_required : "",

                    cancelled: current_booking.cancelled ? current_booking.cancelled : "",
                    cancelled_at: current_booking.cancelled_at ? current_booking.cancelled_at : "",

                    paid:
                        current_booking.rates[current_booking.rates.length - 1] && current_booking.rates[current_booking.rates.length - 1].paid
                            ? current_booking.rates[current_booking.rates.length - 1].paid
                            : "",
                    paid_at:
                        current_booking.rates[current_booking.rates.length - 1] && current_booking.rates[current_booking.rates.length - 1].paid_at
                            ? current_booking.rates[current_booking.rates.length - 1].paid_at
                            : "",
                    deposit_paid:
                        current_booking.rates[current_booking.rates.length - 1] && current_booking.rates[current_booking.rates.length - 1].deposit_paid
                            ? current_booking.rates[current_booking.rates.length - 1].deposit_paid
                            : "",
                    deposit_paid_at:
                        current_booking.rates[current_booking.rates.length - 1] && current_booking.rates[current_booking.rates.length - 1].deposit_paid_at
                            ? current_booking.rates[current_booking.rates.length - 1].deposit_paid_at
                            : "",
                    signed_at:
                        current_booking.rates[current_booking.rates.length - 1] && current_booking.rates[current_booking.rates.length - 1].signed_at
                            ? current_booking.rates[current_booking.rates.length - 1].signed_at
                            : "",
                    month_to_month:
                        current_booking.rates[current_booking.rates.length - 1] && current_booking.rates[current_booking.rates.length - 1].month_to_month
                            ? current_booking.rates[current_booking.rates.length - 1].month_to_month
                            : "",
                    signed_lease_at:
                        current_booking.rates[current_booking.rates.length - 1] && current_booking.rates[current_booking.rates.length - 1].signed_lease_at
                            ? current_booking.rates[current_booking.rates.length - 1].signed_lease_at
                            : "",
                    signed_lease_joint_at:
                        current_booking.rates[current_booking.rates.length - 1] && current_booking.rates[current_booking.rates.length - 1].signed_lease_joint_at
                            ? current_booking.rates[current_booking.rates.length - 1].signed_lease_joint_at
                            : "",

                    override_property_int: current_booking.property_override === null ? null : 1,
                    property_override: current_booking.property_override,
                    price_override: current_booking.rates[current_booking.rates.length - 1]?.price_override ? current_booking.rates[current_booking.rates.length - 1].price_override : 0,
                }}
                onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                    let override_property = null;
                    if (process.env.REACT_APP_COLOUR === "UC") {
                        if (values.override_property_int === 1) {
                            override_property = values.property_override;
                        } else {
                            override_property = -1;
                        }
                    }
                    let submitData = {
                        user: current_staff,
                        // url: current_booking.user.edit_url,
                        url: current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url,
                        property_id: values.property_id,
                        unit_type: values.unit_type,
                        unit_id: values.unit_id,
                        parking: values.parking,
                        parking_fee: values.carport ? values.parking_fee : null,
                        monthly_rental: formatToFloat(values.monthly_rental),
                        deposit_price: formatToFloat(values.deposit_price),
                        escalation: formatToFloat(values.escalation),
                        lease_term: values.lease_term,
                        deposit_type: values.deposit_type,
                        surance_amount: formatToFloat(values.surance_amount),
                        booking_fee_price: formatToFloat(values.booking_fee_price),
                        admin_fee: formatToFloat(values.admin_fee),
                        key_deposit: formatToFloat(values.key_deposit),
                        // effluent: default_values ? default_values.effluent : null,
                        effluent: values.effluent ? values.effluent : default_values ? default_values.effluent : null,
                        // refuse: default_values ? default_values.refuse : null,
                        refuse: values.refuse ? values.refuse : default_values ? default_values.refuse : null,
                        special_deduction: formatToFloat(values.special_deduction),

                        lease_surance: values.lease_surance,

                        carport: values.carport,
                        invoice_monthly_hide: values.invoice_monthly_hide,
                        invoice_monthly_total: values.invoice_monthly_total,
                        monthly_rental_wording: values.monthly_rental_wording,
                        special_wording: values.special_wording,

                        lease_start: values.lease_start,
                        lease_end: values.lease_end,
                        occupation_date: values.occupation_date,
                        size_required: values.size_required,

                        paid: values.paid,
                        paid_at: values.paid_at,

                        cancelled: values.cancelled,
                        cancelled_at: values.cancelled_at,

                        deposit_paid: values.deposit_paid,
                        deposit_paid_at: values.deposit_paid_at,

                        signed_at: values.signed_at,
                        month_to_month: values.month_to_month,
                        signed_lease_at: values.signed_lease_at,
                        signed_lease_joint_at: values.signed_lease_joint_at,

                        type: type,
                        tag_id: tag_id,

                        property_override: override_property,

                        price_override: values.price_override,
                    };

                    await axios({
                        method: "put",
                        url: `${process.env.REACT_APP_API_URL}user`,
                        data: submitData,
                    })
                        .then(() => {
                            // setDoc(
                            //     doc(db, "Bookings", String(current_booking.id)),
                            //     {
                            //         property_id: values.property_id,
                            //         unit_type: values.unit_type,
                            //         unit_id: values.unit_id,
                            //     },
                            //     { merge: true }
                            // );
                            setIgnoreRefresh(true);
                            dispatch(getBooking(current_booking.id));
                            toast(<NotificationToast title="Success" message="Data has been updated sucessfully" />);
                        })
                        .catch((error) => {
                            console.log(error);
                            toast(<NotificationToast title="Something went wrong" message="" type="error" />);
                        });

                    setSubmitting(false);
                }}
            >
                {({ values, touched, errors, handleSubmit, setFieldValue, setFieldTouched, isValid, isSubmitting, dirty }) => (
                    <Form onSubmit={handleSubmit} className="rounded-3xl">
                        <div className="pb-6 grid gap-x-2 gap-y-8 grid-cols-2">
                            {(process.env.REACT_APP_COLOUR === "QL" || process.env.REACT_APP_COLOUR === "QP") && (
                                <>
                                    <QuickFormSelect
                                        label="Building"
                                        name="property_id"
                                        getUnits={getUnits}
                                        getUnitTypes={getUnitTypes}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        value={values.property_id}
                                        touched={touched.property_id}
                                        error={errors.property_id}
                                        options={properties}
                                    />
                                    {process.env.REACT_APP_COLOUR === "QL" ? (
                                        <QuickFormSelect
                                            label="Unit Type"
                                            name="unit_type"
                                            onChange={setFieldValue}
                                            onBlur={setFieldTouched}
                                            value={values.unit_type}
                                            touched={touched.unit_type}
                                            error={errors.unit_type}
                                            options={unitTypes.length > 0 ? unitTypes : "none"}
                                        />
                                    ) : (
                                        ""
                                    )}
                                    <QuickFormSelect
                                        label="Unit"
                                        name="unit_id"
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        value={values.unit_id}
                                        touched={touched.unit_id}
                                        error={errors.unit_id}
                                        options={units.length > 0 ? units : "none"}
                                    />
                                    {/* {console.log(current_booking)} */}
                                    {/* <QuickFormSelect
                                    label="Bed"
                                    name="bed_id"
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    value={values.bed_id}
                                    touched={touched.bed_id}
                                    error={errors.bed_id}
                                    options={beds.length > 0 ? beds : "none"}
                                /> */}

                                    {process.env.REACT_APP_COLOUR === "QL" ? (
                                        <>
                                            <Field
                                                name="carport"
                                                as={QuickFormText}
                                                label="Parking / Carport"
                                                tooltip="Enter the Carport / Garage Number here, otherwise leave blank if not applicable"
                                            />
                                            {values.carport && <Field name="parking_fee" as={QuickFormText} label="Parking Fee" prepend="R" currency="true" disabled />}
                                            <Field name="monthly_rental_wording" as={QuickFormText} label="Monthly Wording" />
                                            <Field name="special_wording" as={QuickFormText} label="Special Wording" />
                                            <Field name="monthly_rental" as={QuickFormText} label="Monthly Rental" prepend="R" currency="true" />
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </>
                            )}

                            {process.env.REACT_APP_COLOUR === "UC" && (
                                <div className="flex flex-col mt-1">
                                    {current_staff.isSuper == 1 || current_staff.email == "rowan@qholdings.co.za" ? (
                                        <Field name="monthly_rental" as={QuickFormText} currency="true" hidden={false} label="Monthly Rental" />
                                    ) : (
                                        <>
                                            <Field name="monthly_rental" as={QuickFormText} currency="true" hidden={true} />
                                            <label className="text-sm text-dark_background">Monthly Rental</label>
                                            <div className="flex rounded-md mt-3">
                                                <span className="inline-flex items-center px-1 rounded-l-md font-light text-sm">
                                                    R{" "}
                                                    {current_booking.rates[current_booking.rates.length - 1] && current_booking.rates[current_booking.rates.length - 1].monthly_rental
                                                        ? current_booking.rates[current_booking.rates.length - 1].monthly_rental
                                                        : ""}
                                                </span>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}

                            {process.env.REACT_APP_COLOUR === "QP" && (
                                <div className="flex flex-col mt-1">
                                    <Field name="monthly_rental" as={QuickFormText} prepend="R" currency="true" label="Monthly Rental" />
                                </div>
                            )}

                            {process.env.REACT_APP_COLOUR === "QP" ? (
                                <>
                                    <Field name="escalation" type="text" as={QuickFormText} label="Escalation" prepend="%" currency="true" />
                                    <div className="flex flex-col mt-1">
                                        <SettingsSelect
                                            label="Lease Term"
                                            name="lease_term"
                                            onChange={setFieldValue}
                                            onBlur={setFieldTouched}
                                            value={values.lease_term}
                                            touched={touched.lease_term}
                                            error={errors.lease_term}
                                            options={leaseTerms}
                                        />
                                    </div>
                                    <div className="flex flex-col mt-1">
                                        <SettingsSelect
                                            label="Deposit"
                                            name="deposit_type"
                                            onChange={setFieldValue}
                                            onBlur={setFieldTouched}
                                            value={values.deposit_type}
                                            touched={touched.deposit_type}
                                            error={errors.deposit_type}
                                            options={depositList}
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Field name="deposit_price" as={QuickFormText} label="Deposit" prepend="R" currency="true" disabled={current_staff?.canEditApplicantFields == 1 ? false : true} />
                                </>
                            )}

                            {process.env.REACT_APP_COLOUR === "UC" && (
                                <Field
                                    name="booking_fee_price"
                                    as={QuickFormText}
                                    label="Booking Fee"
                                    prepend="R"
                                    currency="true"
                                    disabled={current_staff?.canEditApplicantFields == 1 ? false : true}
                                />
                            )}

                            {process.env.REACT_APP_COLOUR === "QL" && <Field name="surance_amount" as={QuickFormText} label="Deposit Waiver Amount" prepend="R" currency="true" disabled={true} />}
                        </div>

                        {(current_staff.isSuper == 1 || current_staff.email == "rowan@qholdings.co.za") && process.env.REACT_APP_COLOUR == "UC" && (
                            <div className="flex flex-col mt-1">
                                <Field name="price_override" as={SettingsSwitch} label="Override Monthly Rental" />
                            </div>
                        )}

                        {process.env.REACT_APP_COLOUR === "UC" && (
                            <>
                                {current_booking.student_type == 4 || current_booking.student_type == 5 || current_booking.student_type == 6 || current_booking.student_type == 1 ? (
                                    <>
                                        <CustomButton
                                            text="Make NSFAS Pricing"
                                            styling="w-full"
                                            icon={faFileInvoiceDollar}
                                            onClick={() => make_nsfas_pricing()}
                                            loading={nsfasButtonLoading}
                                            loadingtext={"Loading"}
                                        />
                                        <br />
                                    </>
                                ) : (
                                    ""
                                )}
                            </>
                        )}

                        {process.env.REACT_APP_COLOUR === "QL" ? (
                            <div className="space-y-4 grid-cols-12 mb-5">
                                <Field name="lease_surance" as={SettingsSwitch} label="Deposit Waiver" description=" " />
                            </div>
                        ) : (
                            ""
                        )}

                        <div className="pb-6 grid gap-x-2 gap-y-8 grid-cols-2">
                            {process.env.REACT_APP_COLOUR === "QL" && (
                                <>
                                    <Field name="admin_fee" as={QuickFormText} label="Admin Fee" prepend="R" currency="true" />
                                    <Field name="refuse" as={QuickFormText} label="Refuse" prepend="R" currency="true" />
                                    <Field name="effluent" as={QuickFormText} label="Effluent" prepend="R" currency="true" />
                                    <Field name="key_deposit" as={QuickFormText} label="Key Deposit" prepend="R" currency="true" />
                                    <Field name="special_deduction" as={QuickFormText} label="Deduction" prepend="R" currency="true" />
                                </>
                            )}

                            {process.env.REACT_APP_COLOUR === "UC" || process.env.REACT_APP_COLOUR === "QL" ? (
                                <>
                                    <Field name="lease_start" type="text" as={QuickFormDate} label="Lease Start Date" disabled={current_staff?.canEditApplicantFields == 1 ? false : true} />
                                    <Field name="lease_end" type="text" as={QuickFormDate} label="Lease End Date" disabled={current_staff?.canEditApplicantFields == 1 ? false : true} />
                                </>
                            ) : (
                                <>
                                    <Field name="occupation_date" type="text" as={QuickFormDate} label="Occupation Date" />
                                    <Field name="size_required" type="number" as={QuickFormText} label="Size Required (SQM)" />
                                </>
                            )}
                        </div>

                        {process.env.REACT_APP_COLOUR != "QP" ? (
                            <>
                                <Field name="invoice_monthly_total" as={SettingsSwitch} label="Ignore monthly rental towards total" />
                                <Field name="invoice_monthly_hide" as={SettingsSwitch} label="Hide Monthly Rental" />
                            </>
                        ) : (
                            ""
                        )}

                        {process.env.REACT_APP_COLOUR === "UC" && (
                            <>
                                {current_booking?.payfast && current_booking?.payfast.length > 0 ? (
                                    <div class="border px-1 mt-2">
                                        <p className="font-bold italic underline">PayFast Last Payment</p>
                                        <p>
                                            <b>Status:</b> {current_booking?.payfast[current_booking?.payfast.length - 1].payment_status}
                                            <br />
                                            <b>Description:</b> {current_booking?.payfast[current_booking?.payfast.length - 1].item_description}
                                            <br />
                                            <b>Amount:</b> R {current_booking?.payfast[current_booking?.payfast.length - 1].amount_gross}
                                        </p>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </>
                        )}

                        <div className="space-y-4 sm:grid sm:grid-cols-6 gap-2">
                            {process.env.REACT_APP_COLOUR === "UC" && (
                                <>
                                    <Field name="paid" as={SettingsSwitch} label="Booking Fee Paid" description=" " />
                                    <Field name="paid_at" type="text" as={QuickFormDate} label="At" />
                                </>
                            )}

                            <Field name="deposit_paid" as={SettingsSwitch} label="Deposit Paid" description=" " />
                            <Field name="deposit_paid_at" type="text" as={QuickFormDate} label="At" />

                            {process.env.REACT_APP_COLOUR === "QL" || process.env.REACT_APP_COLOUR === "QP" || process.env.REACT_APP_COLOUR === "UC" ? (
                                <>
                                    <Field name="signed_at" as={SettingsSwitch} label="Lease Signed" description=" " />
                                    <Field name="signed_lease_at" type="text" as={QuickFormDate} label="At" />
                                </>
                            ) : (
                                ""
                            )}

                            <Field name="cancelled" as={SettingsSwitch} label="Cancel Signed" description=" " />
                            <Field name="cancelled_at" type="text" as={QuickFormDate} label="At" />
                        </div>
                        {process.env.REACT_APP_COLOUR === "QL" || (process.env.REACT_APP_COLOUR === "UC" && current_booking.bed_id > 0) ? (
                            <div className="mt-5">
                                <Field name="month_to_month" as={SettingsSwitch} label="Month to Month" description=" " />
                            </div>
                        ) : (
                            ""
                        )}

                        {process.env.REACT_APP_COLOUR === "UC" && current_booking.bed_id > 0 ? (
                            <div className="mt-5">
                                <Field name="override_property_int" as={SettingsSwitch} label="Property Address Override" description=" " />

                                {values.override_property_int === 1 ? (
                                    <QuickFormSelect label="Building Override Address" name="property_override" onChange={setFieldValue} value={values.property_override} options={properties} />
                                ) : (
                                    ""
                                )}
                            </div>
                        ) : (
                            ""
                        )}

                        {values.joint_application !== 0 && <Field name="signed_lease_joint_at" type="text" as={QuickFormDate} label="Joint Signed Lease At" />}
                        <br />
                        {!current_staff.building_manager ? (
                            <SubmitButton name="Save" disabled={!isValid || isSubmitting || !dirty} isSubmitting={isSubmitting} icon={faSave} text="Update" style={{ width: "100%" }} />
                        ) : (
                            ""
                        )}
                    </Form>
                )}
            </Formik>
            <div className="border-t-2 border-white mt-2 py-2 w-auto">
                {/* Must check if property and all data is set */}
                {!current_staff.building_manager ? (
                    <>
                        {/* {process.env.REACT_APP_COLOUR === "QL" || process.env.REACT_APP_COLOUR === "UC" && current_booking.bed_id > 0 ? */}
                        <CustomButton
                            text="Generate Invoice"
                            styling="w-full"
                            icon={faFileInvoiceDollar}
                            onClick={() => generate_document("invoice")}
                            loading={invoiceButtonLoading ? 1 : 0}
                            loadingtext={invoiceButtonLoadingText}
                        />
                        {/* : "" } */}
                        {/*  || (process.env.REACT_APP_COLOUR === "UC" && current_booking.bed_id > 0) */}
                        {process.env.REACT_APP_COLOUR === "QL" || process.env.REACT_APP_COLOUR === "QP" || process.env.REACT_APP_COLOUR === "UC" ? (
                            <CustomButton
                                text="Generate Lease"
                                styling="w-full mt-2"
                                icon={faFileSignature}
                                onClick={() => {
                                    generate_document("lease");
                                }}
                                loading={leaseButtonLoading ? 1 : 0}
                                loadingtext={leaseButtonLoadingText}
                            />
                        ) : (
                            ""
                        )}

                        {process.env.REACT_APP_COLOUR === "QL" || process.env.REACT_APP_COLOUR === "QP" || process.env.REACT_APP_COLOUR === "UC" ? (
                            <CustomButton
                                text="Generate & Send Lease"
                                styling="w-full mt-2"
                                icon={faFileSignature}
                                onClick={() => {
                                    generate_document("lease_send");
                                }}
                                loading={leaseButtonLoading ? 1 : 0}
                                loadingtext={leaseButtonLoadingText}
                            />
                        ) : (
                            ""
                        )}

                        {process.env.REACT_APP_COLOUR === "QP" ? (
                            <CustomButton
                                text="Generate Surety"
                                styling="w-full mt-2"
                                icon={faTreasureChest}
                                onClick={() => {
                                    generate_document("surety");
                                }}
                                loading={suretyButtonLoading ? 1 : 0}
                                loadingtext={suretyButtonLoadingText}
                            />
                        ) : (
                            ""
                        )}

                        {process.env.REACT_APP_COLOUR === "QL" || (process.env.REACT_APP_COLOUR === "UC" && current_booking.bed_id > 0) ? (
                            <>
                                <CustomButton
                                    text="Lease Addendum"
                                    styling="w-full mt-2"
                                    icon={faFileSignature}
                                    onClick={() =>
                                        dispatch(
                                            openModal({
                                                modal_type: "ModalChangeUnit",
                                                modal_props: {
                                                    setDoc,
                                                    db,
                                                    units,
                                                    unitTypes,
                                                    current_staff,
                                                    default_values,
                                                    getUnits,
                                                    getUnitTypes,
                                                },
                                            })
                                        )
                                    }
                                />

                                {current_staff.id === 1 ? (
                                    <>
                                        <CustomButton
                                            text="Re-Generate Addendum"
                                            styling="w-full mt-2"
                                            icon={faFileSignature}
                                            onClick={() => {
                                                re_generate_addendum(current_booking.id);
                                            }}
                                            loading={leaseButtonLoading ? 1 : 0}
                                            loadingtext={leaseButtonLoadingText}
                                        />
                                    </>
                                ) : (
                                    ""
                                )}
                            </>
                        ) : (
                            ""
                        )}
                    </>
                ) : (
                    ""
                )}

                {current_booking.invoice_url && current_booking.lease_url && !current_staff.building_manager && (
                    <CustomButton
                        text="Email Invoice and Lease"
                        styling="w-full mt-2"
                        icon={faSquareEnvelope}
                        onClick={() => email_documents()}
                        loading={emailButtonLoading ? 1 : 0}
                        loadingtext={emailButtonLoadingText}
                    />
                )}
            </div>
        </div>
    );
}
