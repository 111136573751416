import React, {useEffect, useState, useRef} from "react";
import {useSelector, useDispatch} from "react-redux";

import TypeIcon from "../Helpers/TypeIcon";
import TimeSince from "../Helpers/TimeSince";
import ProfilePicture from "../Auth/ProfilePicture";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faEye,
	faPaperclip,
	faEnvelopesBulk,
	faInfoCircle,
	faCommentSmile,
	faCommentXmark,
	faRobot,
	faCommentQuestion
} from "@fortawesome/pro-regular-svg-icons";
import {openModal} from "../../store/modal/modalReducer";
import {faHeartPulse} from "@fortawesome/pro-solid-svg-icons";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function BookingMessages({messages, setMsgCount}) {
	const messagesEndRef = useRef(null);
	const dispatch = useDispatch();
	const {current_booking} = useSelector((state) => state.booking);
	const {staff} = useSelector((state) => state.auth);
	const isImage = (filePath) => /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(filePath);
	const isPDF = (filePath) => /\.pdf$/i.test(filePath);
	const isExcel = (filePath) => /\.xlsx$/i.test(filePath);

	// detect screensize, we don't want to scroll to bottom on mobile
	const [width, setWidth] = useState(window.innerWidth);

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}

	const [loadAll, setLoadAll] = useState(false);
	const [msgLoading, setMsgLoading] = useState(false);

	function MessageColour(id, platform, collection) {
		if (platform === "note") {
			return "bg-yellow-200";
		} else if (platform === "audit") {
			return "bg-red-200";
		} else if (platform === "website") {
			return "bg-green-200";
		} else if (platform === "failed") {
			return "bg-red-300";
		} else if (staff.find((e) => e.id === id) || id == "-1" || id == "-2") {
			if (platform === "whatsapp bot") {
				return "bg-orange-200";
			} else if (collection === 1) {
				return "bg-purple-200";
			} else {
				return "bg-blue-200";
			}
		} else {
			return "bg-gray-200";
		}
	}

	function BorderColour(platform, collection, broker) {
		if (platform === "failed") {
			return "border-2 border-red-600";
		} else {
			if (collection === 1) {
				return "border-2 border-purple-600";
			} else if (collection === 2) {
				return "border-2 border-sky-500";
			} else if (broker === 1) {
				return "border-2 border-rose-400";
			} else {
				return "";
			}
		}
	}

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({behavior: "smooth"});
	};

	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		if (width > 1200) scrollToBottom();
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, [messages]);

	async function loadMoreMsgs() {
		setMsgLoading(true);
		setMsgCount(5000);
		setMsgLoading(false);
		setLoadAll(true);
	}

	// console.log("current_booking", current_booking)

	return (
		<div className="overflow-y-scroll scroll-smooth xl:absolute w-full h-96 xl:h-2/3 xl:pt-14 xl:pb-10">
			{current_booking && (
				<>
					<div className="overflow-y-auto flex-col-reverse">
						{/* {loadAll === false && (<button type="button" className='relative text-white uppercase items-center px-4 py-2 rounded-lg text-sm focus:z-10 focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 bg-primary hover:bg-primary_hover focus:ring-primary mt-5 w-full text-center' onClick={() => loadMoreMsgs()}> {msgLoading === false ? 'Load all messages' : <><FontAwesomeIcon className="w-4 h-4 animate-spin" icon={faLoader} /> Loading</> }</button>)} */}

						{messages.length ? (
							messages &&
							messages.map((d) => {
								return (
									<div
										key={d.id}
										className={classNames(staff.find((e) => e.id === parseInt(d.from)) || d.from == -1 || d.from == -2 ? "justify-end ml-24" : "justify-start mr-24", "flex py-4")}
									>
										<div
											className={classNames(staff.find((e) => e.id === parseInt(d.from) || d.from == -1 || d.from == -2) ? "" : "flex-row-reverse", "flex ")}>
											<div>
												<div
													className={classNames(
														MessageColour(parseInt(d.from), d.platform, d.collection),
														"p-4 rounded-md text-sm max-w-sm break-words",
														BorderColour(d.platform, d.collection, d.broker)
													)}
												>
													{d.response !== undefined && d.response !== false ?
														<p className="italic font-semibold underline mb-2">{d.response}</p> : ""}

													{d.platform === "email" && d.email_subject != null && d.email_subject !== "" && (
														<div
															className="font-bold mb-4">{d.email_subject && "Subject: " + d.email_subject}</div>
													)}

													<div
														className="dangerousPFormat"
														dangerouslySetInnerHTML={{
															__html:
																d.message_html && d.message_html !== ""
																	? new DOMParser()
																		.parseFromString(d.message_html.includes("blockquote") ? d.message_html.split("<blockquote")[0] : d.message_html, "text/html")
																		.body.innerHTML.replace(/<\/?a[^>]*>/g, "")
																		.replace(/<blockquote[^>]*>\|.*$/g, "")
																	: d.message,
														}}
													/>
													{d.failed_reason !== undefined && d.failed_reason !== null ? (
														<>
															<hr/>
															<p className="text-xs italic mt-2">{d.failed_reason}</p>
														</>
													) : (
														""
													)}

													{staff.find((e) => e.id === parseInt(d.from)) && d.files.length > 0 && d.files != "" && (
														<>
															<p className="text-xs text-gray-800 mt-2">Uploaded the
																following file(s)</p>

															{d.files instanceof Array ? (
																d.files.map((file) => (
																	<li key={file} className="text-xs text-gray-800">
																		{file}
																	</li>
																))
															) : (
																<li className="text-xs text-gray-800">{d.files}</li>
															)}
														</>
													)}

													{staff.find((e) => e.id === parseInt(d.from)) && d.file_path && (
														<>
															<p className="text-xs text-gray-800 mt-2">Uploaded the
																following file(s)</p>

															{(isPDF(d.file_path) || isExcel(d.file_path)) ? (
																<a href={d.file_path} target="_blank"
																   rel="noopener noreferrer"
																   className="text-blue-500 underline">
																	View {isPDF(d.file_path) ? "PDF" : "Excel"}
																</a>
															) : isImage(d.file_path) ? (
																<img src={d.file_path} alt="File uploaded"/>
															) : (
																<p className="text-xs text-red-500">Unsupported file
																	type</p>
															)}
														</>
													)}

													{d.from != current_booking?.user?.cell && !staff.find((e) => e.id === parseInt(d.from)) && d.platform === "whatsapp" && (
														<p className="text-xs text-red-500 mt-2 bold">FROM SECOND
															NUMBER<br/>If you get a 24 hour error please make this
															number the primary number.<br/>{d.from}</p>
													)}
												</div>
												<div
													className={classNames(
														staff.find((e) => e.id === parseInt(d.from)) || d.from == -1 || d.from == -2 ? "justify-end" : "justify-start",
														"flex items-center text-gray-900 text-xs"
													)}
												>
													<TypeIcon platform={d.platform} className="w-4 h-4 mr-2"/>
													{d.flow === 1 ? (
														<>
															<FontAwesomeIcon icon={faRobot} className="mr-2"/>
														</>
													) : (
														""
													)}
													{d.platform && d.platform === "email" && (
														<FontAwesomeIcon
															icon={faInfoCircle}
															className="mx-1 cursor-pointer"
															onClick={() =>
																dispatch(
																	openModal({
																		modal_type: "ModalMailRawInfo",
																		modal_props: {
																			d,
																		},
																	})
																)
															}
														/>
													)}
													<TimeSince time={d.timestamp} className="mr-2"/>
													<div
														className={classNames(staff.find((e) => e.id === parseInt(d.from)) || d.from == -1 || d.from == -2 ? "mr-auto" : "ml-auto")}>
														{d.files && d.files.length > 0 && d.files[0] > 0 && (
															<FontAwesomeIcon
																icon={faPaperclip}
																className="hover:cursor-pointer hover:text-gray-500 mr-2"
																title="View the attachments for more information"
															/>
														)}
														{d.files.length === 1 && d.files[0] > 0 && (
															<FontAwesomeIcon
																icon={faEye}
																className="hover:cursor-pointer hover:text-gray-500"
																title="View"
																onClick={() =>
																	dispatch(
																		openModal({
																			modal_type: "ModalViewFiles",
																			modal_props: {
																				files: [
																					{
																						id: d.files[0],
																					},
																				],
																			},
																		})
																	)
																}
															/>
														)}
													</div>

													{staff.find((e) => e.id === parseInt(d.from)) && d.from !== -1 && d.from !== -2 ? (
														<>
															{d.booking_id != current_booking.id ? (
																<FontAwesomeIcon icon={faCommentXmark}
																				 className="text-lg"/>
															) : (
																<FontAwesomeIcon icon={faCommentSmile}
																				 className="text-lg"/>
															)}
														</>
													) : (
														""
													)}
													{d.bulk && d.bulk === true &&
														<FontAwesomeIcon icon={faEnvelopesBulk}/>}

													{d.conversation_id != null && (
														<div className="ml-2">
															<button onClick={() => dispatch(openModal({
																modal_type: "ModalStatus",
																modal_props: {id: d.id}
															}))}>
																<FontAwesomeIcon icon={faHeartPulse}
																				 className="text-lg"/>
															</button>
														</div>
													)}
												</div>
											</div>
											<div className="text-left flex-shrink-0">
												<ProfilePicture id={parseInt(d.from)}/>
											</div>
										</div>
									</div>
								);
							})
						) : (
							<p className="mx-auto">No Chat history Found</p>
						)}
						<div ref={messagesEndRef}/>
					</div>
				</>
			)}
		</div>
	);
}
